import { useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';

import theme from '@styles/theme';

import StyledModal from '@components/styled/StyledModal';
import StyledInput from '@components/styled/StyledInput';
import StyledButton from '@components/styled/StyledButton';

type RegisterPassModalProps = {
    id: number;
    refetch: () => void;
    status: string;
    cardSerial: string;
    setStatus: React.Dispatch<React.SetStateAction<string>>;
    setProcessedModal: React.Dispatch<React.SetStateAction<boolean>>;
    setCardSerial: React.Dispatch<React.SetStateAction<string>>;
    submitHandler: (status: string) => void;
};
const RegisterPassModal = ({
    id,
    refetch,
    status,
    cardSerial,
    setStatus,
    setProcessedModal,
    setCardSerial,
    submitHandler,
}: RegisterPassModalProps) => {
    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        ref.current!.focus();
    }, []);

    const closeModalHandler = useCallback(() => {
        setStatus('unprocessed');
        setProcessedModal(false);
        setCardSerial('');
    }, [setStatus, setProcessedModal, setCardSerial]);

    return (
        <StyledModal
            title="방문증 번호 입력"
            marginBottom={40}
            isOpen={status === 'processed'}
            closeBtnHandler={closeModalHandler}
        >
            <StyledInput
                value={cardSerial}
                onChange={(e) => setCardSerial(e.target.value)}
                placeholder="방문증 번호를 입력해주세요"
                maxLength={30}
                ref={ref}
            />
            <ButtonWrapper>
                <StyledButton
                    title="취소"
                    width="140px"
                    fontColor={`${theme.colors.blackColor}`}
                    bgColor={`${theme.colors.whiteColor}`}
                    border={`${theme.colors.componentBorder} 1px solid`}
                    onClick={closeModalHandler}
                />
                <StyledButton
                    title="발급하기"
                    width="140px"
                    onClick={() => {
                        submitHandler(status);
                        setProcessedModal(false);
                    }}
                />
            </ButtonWrapper>
        </StyledModal>
    );
};

export default RegisterPassModal;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: end;
    gap: 8px;
    margin-top: 32px;
`;
