import React, { useEffect, useRef } from 'react';
import {
    HiChevronDoubleLeft,
    HiChevronDoubleRight,
    HiChevronLeft,
    HiChevronRight,
} from 'react-icons/hi2';
import styled from 'styled-components';
import { darken, lighten } from 'polished';
import theme from '@styles/theme';
import SelectBoxArrow from '@assets/icons/selectboxArrow.png';
import StackIcon from '@assets/icons/stackIcon.svg';

type PaginationProps = {
    paginationOpen: boolean;
    setPaginationOpen: React.Dispatch<React.SetStateAction<boolean>>;
    pageGroupLength: number;
    totalLength: number;
    take: number;
    cursor: number;
    setTake: React.Dispatch<React.SetStateAction<number>>;
    setPageIndex: React.Dispatch<React.SetStateAction<number>>;
    setCursor: React.Dispatch<React.SetStateAction<number>>;
    prevPageHandler: () => void;
    nextPageHandler: () => void;
    prevPageGroupHandler: () => void;
    nextPageGroupHandler: () => void;
    modalCloseHandler?: () => void;
    resetPaginationHandler: () => void;
};

const Pagination = ({
    paginationOpen,
    setPaginationOpen,
    pageGroupLength,
    totalLength,
    take,
    cursor,
    setTake,
    setPageIndex,
    setCursor,
    prevPageHandler,
    nextPageHandler,
    prevPageGroupHandler,
    nextPageGroupHandler,
    modalCloseHandler,
    resetPaginationHandler,
}: PaginationProps) => {
    const outRef = useRef<HTMLUListElement>(null);

    const lengthStartNum = totalLength ? cursor + 1 : 0;
    const lengthEndNum =
        totalLength < cursor + take ? totalLength : cursor + take;

    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            // 해당 이벤트가 영역 밖이라면
            if (outRef.current && !outRef.current.contains(e.target as Node)) {
                setPaginationOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [setPaginationOpen]);

    return (
        <Container>
            <PageInfoContainer>
                <PageInfoText>번호</PageInfoText>
                <PageInfo>{`${lengthStartNum} - ${lengthEndNum} of ${
                    totalLength ? totalLength : 0
                }`}</PageInfo>
                <PageInfoImg src={StackIcon} alt="stack icon" />
                <PageInfoSelectBox
                    name="pageAmount"
                    value={take}
                    onChange={(e) => {
                        setTake(Number(e.target.value));
                        resetPaginationHandler();
                    }}
                >
                    <Option value={10}>10</Option>
                    <Option value={20}>20</Option>
                    <Option value={30}>30</Option>
                </PageInfoSelectBox>
            </PageInfoContainer>
            <PaginationContainer>
                <PaginationBtn onClick={prevPageGroupHandler}>
                    <HiChevronDoubleLeft
                        color={`${theme.colors.disabled}`}
                        size={20}
                    />
                </PaginationBtn>
                <PaginationBtn onClick={prevPageHandler}>
                    <HiChevronLeft
                        color={`${theme.colors.disabled}`}
                        size={20}
                    />
                </PaginationBtn>
                <PageText>페이지</PageText>
                <PageSelectBox
                    onClick={() => setPaginationOpen(!paginationOpen)}
                >
                    {(cursor + take) / take}
                </PageSelectBox>
                <PaginationBtn onClick={nextPageHandler}>
                    <HiChevronRight
                        color={`${theme.colors.disabled}`}
                        size={20}
                    />
                </PaginationBtn>
                <PaginationBtn onClick={nextPageGroupHandler}>
                    <HiChevronDoubleRight
                        color={`${theme.colors.disabled}`}
                        size={20}
                    />
                </PaginationBtn>

                {/* 페이지네이션 셀렉트박스 */}
                {paginationOpen && (
                    <PaginationListContainer ref={outRef}>
                        {pageGroupLength !== 0 ? (
                            <PaginationList
                                key="prev"
                                onClick={prevPageGroupHandler}
                            >
                                이전
                            </PaginationList>
                        ) : (
                            <></>
                        )}
                        {[...Array(10)].map((_, i) => (
                            <React.Fragment
                                key={pageGroupLength / take + 1 + i}
                            >
                                {pageGroupLength / take + 1 + i <=
                                    Math.ceil(totalLength / take) && (
                                    <PaginationList
                                        onClick={() => {
                                            setCursor(
                                                pageGroupLength + i * take,
                                            );
                                            setPageIndex(i * take);
                                            setPaginationOpen(!paginationOpen);
                                        }}
                                    >
                                        {pageGroupLength / take + 1 + i}
                                    </PaginationList>
                                )}
                            </React.Fragment>
                        ))}
                        {totalLength - take * 10 <= pageGroupLength ? (
                            <></>
                        ) : (
                            <PaginationList
                                key="next"
                                onClick={nextPageGroupHandler}
                            >
                                다음
                            </PaginationList>
                        )}
                    </PaginationListContainer>
                )}
            </PaginationContainer>
        </Container>
    );
};

export default Pagination;

const Container = styled.section`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 33px;
`;
const PageInfoContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    font-family: AppleSDGothicNeoBold, sans-serif;
`;

const PageInfoText = styled.span`
    display: inline-block;
    margin-right: 12px;
    color: ${theme.colors.disabled};
`;

const PageInfo = styled.span`
    display: inline-block;
    margin-right: 12px;
`;

const PageInfoImg = styled.img`
    display: block;
`;

const PageInfoSelectBox = styled.select`
    margin-left: 12px;
    padding: 0 12px;
    width: 60px;
    height: 32px;
    border: ${theme.colors.paginationBorder} solid 1px;
    border-radius: 4px;
    background: url(${SelectBoxArrow}),
        ${theme.colors.whiteColor} no-repeat center;
    font-family: AppleSDGothicNeoBold, sans-serif;
    cursor: pointer;
    &:hover {
        background-color: ${lighten(0.01, theme.colors.whiteColor)};
    }

    &:active {
        background-color: ${darken(0.01, theme.colors.whiteColor)};
    }
`;

const Option = styled.option``;

const PaginationContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    font-family: AppleSDGothicNeoBold, sans-serif;
`;

const PaginationBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border: ${theme.colors.paginationBorder} solid 1px;
    background-color: ${theme.colors.whiteColor};
    border-radius: 4px;
    cursor: pointer;
    &:hover {
        background-color: ${darken(0.01, theme.colors.whiteColor)};
    }

    &:active {
        background-color: ${darken(0.02, theme.colors.whiteColor)};
    }
`;

const PageText = styled.span`
    margin-left: 12px;
    color: ${theme.colors.disabled};
`;
const PageSelectBox = styled.span`
    margin: 0 12px;
    padding: 8px 12px;
    width: 60px;
    height: 32px;
    border: ${theme.colors.paginationBorder} 1px solid;
    border-radius: 4px;
    background: url(${SelectBoxArrow}) no-repeat center;
    cursor: pointer;
`;

const PaginationListContainer = styled.ul`
    position: absolute;
    top: 0;
    right: 76px;
    z-index: 10;
    width: 60px;
    border: ${theme.colors.paginationBorder} 1px solid;
    border-radius: 4px;
    background-color: ${theme.colors.whiteColor};
`;

const PaginationList = styled.li`
    padding: 8px 10px;
    width: 100%;
    background-color: ${theme.colors.whiteColor};
    cursor: pointer;
    &:hover {
        background-color: ${darken(0.01, theme.colors.whiteColor)};
    }

    &:active {
        background-color: ${darken(0.02, theme.colors.whiteColor)};
    }
`;
