import { useState, useMemo, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useReactiveVar } from '@apollo/client';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import * as P from './Parking.style';
import { SEE_VISITORS_PARKING_LIST_V2 } from '@graphql/queries';
import { Query, Visitors, ReceptionAdmin } from '@graphql/types';
import { selectedSite, newParking, userVar } from '@store/index';
import usePagination from '@hooks/usePagination';
import { useAuth } from '@context/authProvider';

import Pagination from '@components/layout/Pagination';
import ParkingList from '@components/feature/Parking/ParkingList';
import Loader from '@components/share/Loader';
import MuiTableCalendar from '@components/styled/MuiTableCalendar';

const Parking = () => {
    const navigate = useNavigate();
    const { userLogout } = useAuth();
    const [filter, setFilter] = useState('total');
    const [searchDate, setSearchDate] = useState<string | Date>(
        dayjs(new Date()).toISOString(),
    );
    const [take, setTake] = useState(10);
    const [cursor, setCursor] = useState(0);
    // 페이지 그룹 변화 시 게시물 수. 0, take * 10, take * 20 씩으로 증감시켜 사용
    const [pageGroupLength, setPageGroupLength] = useState(0);
    // 데이터 수를 10으로 나누 것. ex) 0, 10, 20..
    const [pageIndex, setPageIndex] = useState(0);
    // 페이지네이션 셀렉트박스 show, hide
    const [paginationOpen, setPaginationOpen] = useState(false);

    const selectSiteName = useReactiveVar(selectedSite);
    const parking = useReactiveVar(newParking);
    const userInfo: ReceptionAdmin = useReactiveVar(userVar);
    const { receptionAdminPermission } = userInfo ?? {};
    const { rap_parking } = receptionAdminPermission ?? {};

    // 접근 권한 없는 사용자 접근 막기
    useEffect(() => {
        if (rap_parking === false) {
            toast.warning('접근 권한이 없는 페이지 입니다.');
            navigate('/people');
        }
    }, [rap_parking, navigate]);

    const { data, loading, refetch } = useQuery<
        Pick<Query, 'seeVisitorsParkingList_v2'>
    >(SEE_VISITORS_PARKING_LIST_V2, {
        variables: {
            searchDate,
            rvCarRegistStatus: filter,
            take: take,
            cursor: cursor,
            workSiteUnique: selectSiteName,
        },
        onCompleted: (data) => {
            if (!data.seeVisitorsParkingList_v2?.tokenExpired as boolean)
                userLogout();
        },
        onError: (err) => console.log(err),
    });

    // socket io 감지
    useEffect(() => {
        (async () => {
            if (parking) {
                await refetch();
            }
        })();
    }, [parking, refetch]);

    const {
        visitorsParkingInfo = [],
        totalLength,
        totalCount,
        processedCount,
        unprocessedCount,
        cancelCount,
    } = useMemo(() => {
        return data?.seeVisitorsParkingList_v2 ?? {};
    }, [data?.seeVisitorsParkingList_v2]);

    const refetchHandler = useCallback(() => {
        refetch();
    }, [refetch]);

    const resetPaginationHandler = useCallback(() => {
        setCursor(0); // 페이지네이션 초기화
        setPageIndex(0);
        setPageGroupLength(0);
    }, []);

    // 페이지네이션
    const {
        prevPageHandler,
        nextPageHandler,
        prevPageGroupHandler,
        nextPageGroupHandler,
    } = usePagination(
        take,
        totalLength as number,
        pageIndex,
        pageGroupLength,
        cursor,
        setPageIndex,
        setPageGroupLength,
        setCursor,
    );

    return (
        <P.Container>
            <P.Header>
                <P.Title>주차 등록 현황</P.Title>
                <P.FilterWrapper>
                    <P.StatusWrapper>
                        <P.Label htmlFor="total">
                            <P.Radio
                                type="radio"
                                id="total"
                                name="registerParking"
                                value="total"
                                checked={filter === 'total'}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                            <P.FilterText>전체 {totalCount}</P.FilterText>
                        </P.Label>
                        <P.Label htmlFor="complete">
                            <P.Radio
                                type="radio"
                                id="complete"
                                name="registerParking"
                                value="complete"
                                checked={filter === 'complete'}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                            <P.FilterText>
                                등록완료 {processedCount}
                            </P.FilterText>
                        </P.Label>
                        <P.Label htmlFor="unprocessed">
                            <P.Radio
                                type="radio"
                                id="unprocessed"
                                name="registerParking"
                                value="unprocessed"
                                checked={filter === 'unprocessed'}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                            <P.FilterText>
                                미등록 {unprocessedCount}
                            </P.FilterText>
                        </P.Label>
                        <P.Label htmlFor="cancel">
                            <P.Radio
                                type="radio"
                                id="cancel"
                                name="registerParking"
                                value="cancel"
                                checked={filter === 'cancel'}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                            <P.FilterText>취소 {cancelCount}</P.FilterText>
                        </P.Label>
                    </P.StatusWrapper>
                    <MuiTableCalendar
                        value={searchDate}
                        onChange={setSearchDate}
                    />
                </P.FilterWrapper>
            </P.Header>
            <P.TableContainer>
                <ParkingList />
                {loading && <Loader />}
                {(visitorsParkingInfo as Visitors[]).map(
                    (list, idx: number) => (
                        <ParkingList
                            key={`${idx}-${list?.rv_id}`}
                            list={list}
                            refetch={refetchHandler}
                        />
                    ),
                )}
                {!loading && !totalLength && (
                    <P.ListEmpty>조회된 리스트가 없습니다.</P.ListEmpty>
                )}
            </P.TableContainer>
            <Pagination
                paginationOpen={paginationOpen}
                setPaginationOpen={setPaginationOpen}
                pageGroupLength={pageGroupLength}
                totalLength={totalLength as number}
                take={take}
                cursor={cursor}
                setTake={setTake}
                setPageIndex={setPageIndex}
                setCursor={setCursor}
                prevPageHandler={prevPageHandler}
                nextPageHandler={nextPageHandler}
                prevPageGroupHandler={prevPageGroupHandler}
                nextPageGroupHandler={nextPageGroupHandler}
                resetPaginationHandler={resetPaginationHandler}
            />
        </P.Container>
    );
};

export default Parking;
