import { useMutation } from '@apollo/client';
import { useState, useEffect, memo, useCallback } from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';
import { toast } from 'react-toastify';
import { SelectChangeEvent } from '@mui/material';

import theme from '@styles/theme';
import { Mutation, Visitors } from '@graphql/types';
import { dateFormat, autoHyphen } from '@utils/common';
import {
    VISITOR_TYPE,
    PATH_TYPE,
    INITIAL_STATUS_OPTIONS,
    PROCESSED_STATUS_OPTIONS,
} from '@constants/index';
import { UPDATE_VISITOR_STATUS_V2 } from '@graphql/mutations';
import { useAuth } from '@context/authProvider';

import MuiSelect from '@components/styled/MuiSelect';
import RegisterPassModal from '@components/share/RegisterPassModal';
import RequestCancelModal from '@components/share/RequestCancelModal';

type VisitorListProps = {
    list?: Visitors;
    refetch?: () => void;
};

const VisitorList = ({ list, refetch }: VisitorListProps) => {
    const { userLogout } = useAuth();
    const [status, setStatus] = useState('');
    const [processedModal, setProcessedModal] = useState(false);
    const [cancelModal, setCancelModal] = useState(false);
    const [cardSerial, setCardSerial] = useState('');
    const [reason, setReason] = useState('');
    const {
        rv_id,
        rv_createdAt,
        rv_type,
        rv_team,
        rv_companyName,
        rv_name,
        rv_cellPhone,
        rv_visitPath,
        rv_carLicenseNumber,
        rv_hour,
        rv_min,
        rv_managerName,
        rv_visitorCardStatus,
        rv_visitorCardSerial,
    } = list ?? {};

    useEffect(() => {
        setStatus(rv_visitorCardStatus as string);
    }, [rv_visitorCardStatus]);

    const [updateVisitorStatus_v2] = useMutation<
        Pick<Mutation, 'updateVisitorStatus_v2'>
    >(UPDATE_VISITOR_STATUS_V2);

    const submitHandler = useCallback(
        async (statusType: string) => {
            await updateVisitorStatus_v2({
                variables: {
                    rvId: rv_id,
                    rvVisitorCardStatus: statusType,
                    rvVisitorCardSerial: cardSerial ? cardSerial : '',
                    rvCancelReason: reason ? reason : '',
                },
                onCompleted: (data) => {
                    if (!data.updateVisitorStatus_v2?.tokenExpired as boolean)
                        userLogout();
                    refetch!();
                    toast.success('방문자 등록 상태를 변경했습니다.');
                    if (processedModal) setProcessedModal(false);
                    if (cancelModal) setCancelModal(false);
                },
                onError: (err) => {
                    console.log(err.message);
                    setCardSerial('');
                    setReason('');
                    switch (statusType) {
                        case 'return':
                            setStatus('processed');
                            break;
                        default:
                            setStatus('unprocessed');
                    }
                },
            });
        },
        [
            rv_id,
            cardSerial,
            reason,
            refetch,
            updateVisitorStatus_v2,
            processedModal,
            cancelModal,
            userLogout,
        ],
    );

    const changeSelectHandler = async (
        e:
            | React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
            | SelectChangeEvent,
    ) => {
        const { value } = e.target;
        switch (value) {
            case 'processed':
                setStatus(value);
                setProcessedModal(true);
                break;
            case 'cancel':
                setStatus(value);
                setCancelModal(true);
                break;
            case 'return':
                if (!window.confirm('방문증을 회수하시겠습니까?')) return;
                await submitHandler(value);
                break;
        }
    };

    if (!list)
        return (
            <TableTitle>
                <CellStatus>상태</CellStatus>
                <CellRequestDate>요청일시</CellRequestDate>
                <CellVisitPass>방문증</CellVisitPass>
                <CellType>구분</CellType>
                <CellCompany>업체명/소속</CellCompany>
                <CellName>이름</CellName>
                <CellPhoneNum>연락처</CellPhoneNum>
                <CellPath>방문경로</CellPath>
                <CellCarNum>차량번호</CellCarNum>
                <CellExpectedDeparture>출차예정시간</CellExpectedDeparture>
                <CellManager>담당자명</CellManager>
            </TableTitle>
        );

    return (
        <>
            <Container $disabled={status === 'return' || status === 'cancel'}>
                <CellStatus>
                    <MuiSelect
                        name="status"
                        value={status}
                        onChange={(e) => changeSelectHandler(e)}
                        options={
                            status === 'processed' || status === 'return'
                                ? PROCESSED_STATUS_OPTIONS
                                : INITIAL_STATUS_OPTIONS
                        }
                        border={false}
                        disabled={status === 'return' || status === 'cancel'}
                    />
                </CellStatus>
                <CellRequestDate>
                    {
                        dateFormat(
                            rv_createdAt as string,
                            'yyyy-MM-dd hh:mm',
                        ) as string
                    }
                </CellRequestDate>
                <CellVisitPass>
                    {rv_visitorCardSerial ? rv_visitorCardSerial : '-'}
                </CellVisitPass>
                <CellType>{VISITOR_TYPE[rv_type as string]}</CellType>
                <CellCompany>{rv_companyName}</CellCompany>
                <CellName>{rv_name}</CellName>
                <CellPhoneNum>
                    {autoHyphen(rv_cellPhone as string)}
                </CellPhoneNum>
                <CellPath>{PATH_TYPE[rv_visitPath as string]}</CellPath>
                <CellCarNum>
                    {rv_carLicenseNumber ? rv_carLicenseNumber : '-'}
                </CellCarNum>
                <CellExpectedDeparture>
                    {rv_hour ? `${rv_hour}:${rv_min ? rv_min : '00'}` : '-'}
                </CellExpectedDeparture>
                <CellManager>{`${rv_team} ${rv_managerName}`}</CellManager>
            </Container>
            {createPortal(
                processedModal && (
                    <RegisterPassModal
                        id={rv_id as number}
                        refetch={refetch as () => void}
                        status={status}
                        cardSerial={cardSerial}
                        setStatus={setStatus}
                        setProcessedModal={setProcessedModal}
                        setCardSerial={setCardSerial}
                        submitHandler={submitHandler}
                    />
                ),
                document.body,
            )}
            {createPortal(
                cancelModal && (
                    <RequestCancelModal
                        id={rv_id as number}
                        refetch={refetch as () => void}
                        status={status}
                        reason={reason}
                        setStatus={setStatus}
                        setCancelModal={setCancelModal}
                        setReason={setReason}
                        submitHandler={submitHandler}
                    />
                ),
                document.body,
            )}
        </>
    );
};

export default memo(VisitorList);

const Container = styled.li<{ $disabled: boolean }>`
    display: flex;
    height: 50px;
    border-bottom: ${theme.colors.componentBorder} 1px solid;

    ${({ $disabled }) =>
        $disabled &&
        css`
            color: ${theme.colors.disabled};
        `};

    ${({ $disabled }) =>
        !$disabled &&
        css`
            &:hover {
                background-color: ${theme.colors.componentBG};
            }
        `};
`;

const TableTitle = styled.li`
    display: flex;
    height: 50px;
    border-bottom: ${theme.colors.blackColor} 1px solid;
`;

const TableCell = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
`;

const CellStatus = styled(TableCell)`
    flex: 10%;
`;

const CellRequestDate = styled(TableCell)`
    flex: 11%;
`;

const CellVisitPass = styled(TableCell)`
    flex: 8%;
`;

const CellType = styled(TableCell)`
    flex: 8%;
`;

const CellCompany = styled(TableCell)`
    flex: 12%;
`;

const CellName = styled(TableCell)`
    flex: 7%;
`;

const CellPhoneNum = styled(TableCell)`
    flex: 9%;
`;

const CellPath = styled(TableCell)`
    flex: 7%;
`;

const CellCarNum = styled(TableCell)`
    flex: 9%;
`;

const CellExpectedDeparture = styled(TableCell)`
    flex: 9%;
`;

const CellManager = styled(TableCell)`
    flex: 10%;
`;
