import { useCallback } from 'react';

const usePagination = (
    take: number,
    totalLength: number,
    pageIndex: number,
    pageGroupLength: number,
    cursor: number,
    setPageIndex: React.Dispatch<React.SetStateAction<number>>,
    setPageGroupLength: React.Dispatch<React.SetStateAction<number>>,
    setCursor: React.Dispatch<React.SetStateAction<number>>,
    modalCloseHandler?: () => void,
) => {
    const prevPageHandler = useCallback(() => {
        if (pageGroupLength === 0 && cursor === 0) {
            alert('마지막 페이지입니다.');
            return;
        }
        if (pageIndex === 0) {
            //이전 페이지 그룹으로 넘어가야되는 경우
            setCursor((prev: number) => prev - take);
            setPageIndex(take * 9);
            setPageGroupLength((prev: number) => prev - take * 10);
        } else {
            //이전 페이지
            setCursor((prev: number) => prev - take);
            setPageIndex((prev: number) => prev - take);
        }
        modalCloseHandler?.();
    }, [
        modalCloseHandler,
        pageGroupLength,
        take,
        cursor,
        pageIndex,
        setPageIndex,
        setPageGroupLength,
        setCursor,
    ]);

    const nextPageHandler = useCallback(() => {
        if (totalLength <= cursor + take) {
            alert('마지막 페이지입니다.');
            return;
        }
        if (pageIndex === take * 9) {
            // 다음 페이지 그룹으로 넘어가야되는 경우
            setCursor((prev: number) => prev + take);
            setPageGroupLength((prev: number) => prev + take * 10);
            setPageIndex(0);
        } else {
            // 다음 페이지
            setCursor((prev: number) => prev + take);
            setPageIndex((prev: number) => prev + take);
        }
        modalCloseHandler?.();
    }, [
        modalCloseHandler,
        take,
        totalLength,
        cursor,
        pageIndex,
        setPageIndex,
        setPageGroupLength,
        setCursor,
    ]);

    const prevPageGroupHandler = useCallback(() => {
        if (pageGroupLength === 0) {
            alert('마지막 페이지 그룹입니다.');
        } else {
            setCursor(pageGroupLength - take * 10);
            setPageIndex(0);
            setPageGroupLength((prev: number) => prev - take * 10);
        }
        modalCloseHandler?.();
    }, [
        modalCloseHandler,
        pageGroupLength,
        take,
        setPageIndex,
        setPageGroupLength,
        setCursor,
    ]);

    const nextPageGroupHandler = useCallback(() => {
        if (totalLength - take * 10 <= pageGroupLength) {
            alert('마지막 페이지 그룹입니다.');
        } else {
            setCursor(pageGroupLength + take * 10);
            setPageIndex(0);
            setPageGroupLength((prev: number) => prev + take * 10);
        }
        modalCloseHandler?.();
    }, [
        modalCloseHandler,
        pageGroupLength,
        take,
        totalLength,
        setPageIndex,
        setPageGroupLength,
        setCursor,
    ]);

    return {
        prevPageHandler,
        nextPageHandler,
        prevPageGroupHandler,
        nextPageGroupHandler,
    };
};

export default usePagination;
