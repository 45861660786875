import { DefaultTheme } from 'styled-components';

const device = {
    // pc: `@media (min-width: 1280px) and (max-width: 1600px)`,
    pc: `@media (min-width: 1601px)`,
};

const colors = {
    // General
    whiteColor: '#FFFFFF',
    blackColor: '#000000',

    // point
    positive: '#0078FF',

    // Icon
    lightIconColor: '#CACBD4',
    darkIconColor: '#AAAAAA',

    // Font
    grayFont: '#7B7B80',
    disabled: '#B3B3B3',
    description: '#A0A0A0',

    //Background
    componentBG: '#F3F3F3',

    // Border
    componentBorder: '#E5E5E5',
    headerBorder: '#E7E7E7',
    paginationBorder: '#F9F9F9',
    modalBorder: '#707070',
};

export type ColorsType = typeof colors;
export type DeviceType = typeof device;

const theme: DefaultTheme = {
    colors,
    device,
};

export default theme;
