import { useMutation } from '@apollo/client';
import { useState, useEffect, memo, useCallback } from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';
import { toast } from 'react-toastify';

import theme from '@styles/theme';
import { dateFormat, autoHyphen } from '@utils/common';

import RegisterTrackingNumber from './RegisterTrackingNumber';

const ShippingList = ({ list }: { list?: any }) => {
    const [trackingNumModal, setTrackingNumModal] = useState(false);
    const {
        pp_id,
        pp_createdAt,
        pp_requestDate,
        pp_status,
        pp_requestor,
        pp_requestorTeam,
        pp_requestorCellphone,
        pp_receiver,
        pp_receiverCellphone,
        pp_productName,
        pp_address,
        pp_trackingNumber,
        pp_collectionScheduleDate,
    } = list ?? {};

    if (!list)
        return (
            <TableTitle>
                <CellStatus>상태</CellStatus>
                <CellRequestDate>요청일시</CellRequestDate>
                <CellRequestPerson>요청자</CellRequestPerson>
                <CellPhoneNum>연락처</CellPhoneNum>
                <CellReceiver>받는분</CellReceiver>
                <CellReceiverContact>받는분 연락처</CellReceiverContact>
                <CellProduct>상품명</CellProduct>
                <CellAddress>주소</CellAddress>
                <CellParcelNumber>운송장번호</CellParcelNumber>
            </TableTitle>
        );

    const openModalHandler = () => {
        if (pp_status) return;
        setTrackingNumModal(true);
    };

    const trackingParcelHandler = (
        e: React.MouseEvent<HTMLElement, MouseEvent>,
        trackingNumber: string,
    ) => {
        if (trackingNumber) {
            e.stopPropagation();
            window.open(
                `https://trace.cjlogistics.com/web/detail.jsp?slipno=${trackingNumber}`,
                '_blank',
                'noopener, noreferer',
            );
        } else {
            return;
        }
    };
    return (
        <>
            <Container $disabled={pp_status} onClick={openModalHandler}>
                <CellStatus>{pp_status ? '발송완료' : '미발송'}</CellStatus>
                <CellRequestDate>
                    {
                        dateFormat(
                            pp_requestDate as string,
                            'yyyy-MM-dd hh:mm',
                        ) as string
                    }
                </CellRequestDate>

                <CellRequestPerson>
                    {pp_requestor} / {pp_requestorTeam}
                </CellRequestPerson>
                <CellPhoneNum>{autoHyphen(pp_requestorCellphone)}</CellPhoneNum>
                <CellReceiver>{pp_receiver}</CellReceiver>
                <CellReceiverContact>
                    {autoHyphen(pp_receiverCellphone)}
                </CellReceiverContact>
                <CellProduct>{pp_productName}</CellProduct>
                <CellAddress>{pp_address}</CellAddress>
                <CellParcelNumber
                    onClick={(e) => trackingParcelHandler(e, pp_trackingNumber)}
                >
                    {pp_trackingNumber ? pp_trackingNumber : '-'}
                </CellParcelNumber>
            </Container>
            {createPortal(
                trackingNumModal && (
                    <RegisterTrackingNumber
                        info={list}
                        setTrackingNumModal={setTrackingNumModal}
                    />
                ),
                document.body,
            )}
        </>
    );
};

export default memo(ShippingList);

const Container = styled.li<{ $disabled: boolean }>`
    display: flex;
    height: 50px;
    border-bottom: ${theme.colors.componentBorder} 1px solid;
    cursor: pointer;
    ${({ $disabled }) =>
        $disabled &&
        css`
            opacity: 0.3;
            cursor: auto;
        `};

    ${({ $disabled }) =>
        !$disabled &&
        css`
            &:hover {
                background-color: ${theme.colors.paginationBorder};
            }
        `};
`;

const TableTitle = styled.li`
    display: flex;
    height: 50px;
    border-bottom: ${theme.colors.blackColor} 1px solid;
`;

const TableCell = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
`;

const CellStatus = styled(TableCell)`
    flex: 7%;
`;

const CellRequestDate = styled(TableCell)`
    flex: 11%;
`;

const CellRequestPerson = styled(TableCell)`
    flex: 10%;
`;

const CellPhoneNum = styled(TableCell)`
    flex: 8%;
`;

const CellReceiver = styled(TableCell)`
    flex: 8%;
`;

const CellReceiverContact = styled(TableCell)`
    flex: 10%;
`;

const CellProduct = styled(TableCell)`
    flex: 9%;
`;

const CellAddress = styled(TableCell)`
    flex: 27%;
`;

const CellParcelNumber = styled(TableCell)`
    flex: 10%;
`;
