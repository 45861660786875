type KeyValueStringType = {
    [key: string]: string;
};
export const VISITOR_TYPE: KeyValueStringType = {
    visitor: '방문객',
    people: '무신사 피플',
} as const;

export const PATH_TYPE: KeyValueStringType = {
    walk: '도보',
    car: '차량',
} as const;

export const STATUS_TYPE: KeyValueStringType = {
    unprocessed: '미처리',
    processed: '방문증 발급',
    cancel: '취소',
    return: '방문증 회수',
} as const;

export const SERVICE_TYPE: KeyValueStringType = {
    registered: '등기',
    parcel: '택배',
} as const;

export const STATUS_OPTIONS: KeyValueStringType[] = [
    { name: '미처리', value: 'unprocessed' },
    { name: '방문증 발급', value: 'processed' },
    { name: '취소', value: 'cancel' },
    { name: '방문증 회수', value: 'return' },
];

export const INITIAL_STATUS_OPTIONS: KeyValueStringType[] = [
    { name: '미처리', value: 'unprocessed' },
    { name: '방문증 발급', value: 'processed' },
    { name: '취소', value: 'cancel' },
];

export const PROCESSED_STATUS_OPTIONS: KeyValueStringType[] = [
    { name: '방문증 발급', value: 'processed' },
    { name: '방문증 회수', value: 'return' },
];

export const PARKING_STATUS_OPTIONS: KeyValueStringType[] = [
    { name: '미등록', value: 'unprocessed' },
    { name: '등록완료', value: 'complete' },
    { name: '취소', value: 'cancel' },
];

export const SERVICE_TYPE_OPTIONS: KeyValueStringType[] = [
    { name: '택배', value: 'parcel' },
    { name: '등기', value: 'registered' },
];
