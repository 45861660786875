import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from '@styles/theme';

export const Header = styled.header`
    position: sticky;
    top: 0;
    width: 100%;
    height: 100px;
    border-bottom: ${theme.colors.componentBorder} 1px solid;
    background-color: ${theme.colors.whiteColor};
`;

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 0 auto;
    max-width: 1280px;
    height: inherit;
`;

export const PrevBtn = styled(Link)`
    svg {
        color: ${theme.colors.blackColor};
    }
`;

export const Container = styled.main`
    margin: 0 auto;
    padding: 100px 0;
    width: 340px;
    height: calc(100vh - 100px);
    text-align: center;
    font-family: AppleSDGothicNeoMedium, sans-serif;
`;

export const Form = styled.form``;

export const Title = styled.h1`
    margin-bottom: 32px;
    font-size: 32px;
    font-family: AppleSDGothicNeoBold, sans-serif;
`;

export const Text = styled.p`
    margin-bottom: 68px;
`;

export const Inquery = styled.span`
    display: block;
    text-align: end;
    color: ${theme.colors.disabled};
    cursor: pointer;
`;
