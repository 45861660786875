import React from 'react';

const useEnter = () => {
    return (
        e: React.KeyboardEvent<HTMLInputElement>,
        enterSubmit: () => void,
    ) => {
        if (e.key === 'Enter') {
            if (e.nativeEvent.isComposing) return;
            e.preventDefault();
            enterSubmit();
        }
    };
};

export default useEnter;
