import { gql } from '@apollo/client';

export const RECEPTION_LOGIN = gql`
    mutation ReceptionLogin($accountId: String!, $password: String!) {
        receptionLogin(accountId: $accountId, password: $password) {
            token
        }
    }
`;

export const AUTHENTICATE_RECEPTION_ADMIN = gql`
    mutation AuthenticateReceptionAdmin(
        $accountId: String!
        $password: String!
    ) {
        authenticateReceptionAdmin(accountId: $accountId, password: $password)
    }
`;

export const PRIV_AUTH_CANCEL_RECEPTION_ADMIN = gql`
    mutation Mutation {
        privAuthCancelReceptionAdmin
    }
`;

export const UPDATE_RECEPTION_ADMIN_PW = gql`
    mutation UpdateReceptionAdminPw(
        $currentPw: String
        $newPw: String
        $confirmPw: String
    ) {
        updateReceptionAdminPw(
            currentPw: $currentPw
            newPw: $newPw
            confirmPw: $confirmPw
        )
    }
`;

// FIXME: 백엔드 체크 필요
export const UPDATE_VISITOR_STATUS_V2 = gql`
    mutation UpdateVisitorStatus_v2(
        $rvId: Int!
        $rvVisitorCardStatus: visitorCardStatus!
        $rvVisitorCardSerial: String!
        $rvCancelReason: String
    ) {
        updateVisitorStatus_v2(
            rv_id: $rvId
            rv_visitorCardStatus: $rvVisitorCardStatus
            rv_visitorCardSerial: $rvVisitorCardSerial
            rv_cancelReason: $rvCancelReason
        ) {
            tokenExpired
            result
        }
    }
`;

// FIXME: 백엔드 체크 필요
export const UPDATE_MUPEOPLE_VISIT_CARD_STATUS = gql`
    mutation UpdateMuPeopleVisitCardStatus(
        $mpvcId: Int!
        $mpvcVisitCardStatus: visitorCardStatus!
        $mpvcVisitCardSerial: String!
        $mpvcCancelReason: String
    ) {
        updateMuPeopleVisitCardStatus(
            mpvc_id: $mpvcId
            mpvc_visitCardStatus: $mpvcVisitCardStatus
            mpvc_visitCardSerial: $mpvcVisitCardSerial
            mpvc_cancelReason: $mpvcCancelReason
        ) {
            tokenExpired
            result
        }
    }
`;

export const UPDATE_VISITOR_PARKING_STATUS_V2 = gql`
    mutation UpdateVisitorParkingStatus_v2(
        $rvId: Int!
        $rvCarRegistStatus: visitorParkingStatus!
    ) {
        updateVisitorParkingStatus_v2(
            rv_id: $rvId
            rv_carRegistStatus: $rvCarRegistStatus
        ) {
            tokenExpired
            result
        }
    }
`;
