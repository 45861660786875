import { useMutation } from '@apollo/client';
import { useState, useEffect, memo, useCallback } from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';
import { toast } from 'react-toastify';

import theme from '@styles/theme';
import { dateFormat } from '@utils/common';
import { SERVICE_TYPE } from '@constants/index';

import AddRecipientPopup from './AddRecipientPopup';

type ReceivedListProps = {
    list?: any;
};

const ReceivedList = ({ list }: ReceivedListProps) => {
    const [recipientPopup, setRecipientPopup] = useState(false);
    const {
        pp_id,
        pp_type,
        pp_createdAt,
        pp_receivedDate,
        pp_keepDate,
        pp_status,
        pp_receiver,
        pp_receiverTeam,
        pp_receiverCellphone,
        pp_sender,
        pp_memo,
        pp_manager,
    } = list ?? {};

    const openModalHandler = () => {
        if (pp_manager) return;
        setRecipientPopup(true);
    };

    const submitHandler = useCallback(() => {
        setRecipientPopup(false);
    }, []);
    if (!list)
        return (
            <TableTitle>
                <CellStatus>상태</CellStatus>
                <CellType>종류</CellType>
                <CellKeepingDate>요청일시</CellKeepingDate>
                <CellReceiverTeam>소속팀</CellReceiverTeam>
                <CellReceiver>성함</CellReceiver>
                <CellSender>발송인</CellSender>
                <CellMemo>특이사항</CellMemo>
                <CellReceivedDate>수령일</CellReceivedDate>
                <CellManager>수령인</CellManager>
            </TableTitle>
        );

    return (
        <>
            <Container $disabled={pp_status} onClick={openModalHandler}>
                <CellStatus>{pp_status ? '수령완료' : '미수령'}</CellStatus>
                <CellType>{SERVICE_TYPE[`${pp_type}`]}</CellType>
                <CellKeepingDate>
                    {
                        dateFormat(
                            pp_keepDate as string,
                            'yyyy-MM-dd hh:mm',
                        ) as string
                    }{' '}
                    (일)
                </CellKeepingDate>
                <CellReceiverTeam>{pp_receiverTeam}</CellReceiverTeam>
                <CellReceiver>{pp_receiver}</CellReceiver>
                <CellSender>{pp_sender}</CellSender>
                <CellMemo>{pp_memo}</CellMemo>
                <CellReceivedDate>
                    {
                        dateFormat(
                            pp_receivedDate as string,
                            'yyyy-MM-dd hh:mm',
                        ) as string
                    }
                </CellReceivedDate>
                <CellManager>{pp_manager ? pp_manager : '-'}</CellManager>
            </Container>
            {createPortal(
                recipientPopup && (
                    <AddRecipientPopup
                        recipientPopup={recipientPopup}
                        setRecipientPopup={setRecipientPopup}
                        submitHandler={submitHandler}
                    />
                ),
                document.body,
            )}
        </>
    );
};

export default memo(ReceivedList);

const Container = styled.li<{ $disabled: boolean }>`
    display: flex;
    height: 50px;
    border-bottom: ${theme.colors.componentBorder} 1px solid;
    cursor: pointer;
    ${({ $disabled }) =>
        $disabled &&
        css`
            opacity: 0.3;
            cursor: auto;
        `};

    ${({ $disabled }) =>
        !$disabled &&
        css`
            &:hover {
                background-color: ${theme.colors.paginationBorder};
            }
        `};
`;

const TableTitle = styled.li`
    display: flex;
    height: 50px;
    border-bottom: ${theme.colors.blackColor} 1px solid;
`;

const TableCell = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
`;

const CellStatus = styled(TableCell)`
    flex: 7%;
`;
const CellType = styled(TableCell)`
    flex: 6%;
`;

const CellKeepingDate = styled(TableCell)`
    flex: 15%;
`;

const CellReceiverTeam = styled(TableCell)`
    flex: 10%;
`;

const CellReceiver = styled(TableCell)`
    flex: 6%;
`;

const CellSender = styled(TableCell)`
    flex: 7%;
`;

const CellMemo = styled(TableCell)`
    flex: 25%;
`;

const CellReceivedDate = styled(TableCell)`
    flex: 13%;
`;

const CellManager = styled(TableCell)`
    flex: 11%;
`;
