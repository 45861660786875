import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import 'react-toastify/dist/ReactToastify.css';

import theme from './theme';

import MusinsaLight from './fonts/Musinsa/musinsa-Light.ttf';
import MusinsaMedium from './fonts/Musinsa/musinsa-Medium.ttf';
import MusinsaBold from './fonts/Musinsa/musinsa-Bold.ttf';
import AppleSDGothicNeoHeavy from './fonts/AppleSDGothic/AppleSDGothicNeoH.ttf';
import AppleSDGothicNeoExtraBold from './fonts/AppleSDGothic/AppleSDGothicNeoEB.ttf';
import AppleSDGothicNeoBold from './fonts/AppleSDGothic/AppleSDGothicNeoB.ttf';
import AppleSDGothicNeoMedium from './fonts/AppleSDGothic/AppleSDGothicNeoM.ttf';
import AppleSDGothicNeoRegular from './fonts/AppleSDGothic/AppleSDGothicNeoR.ttf';

export default createGlobalStyle`
  ${reset}
  
  @font-face {
    font-family: MusinsaLight;
    src: url(${MusinsaLight});
  }

  @font-face {
    font-family: MusinsaMedium;
    src: url(${MusinsaMedium});
  }

  @font-face {
    font-family: MusinsaBold;
    src: url(${MusinsaBold});
  }

  @font-face {
    font-family: AppleSDGothicNeoHeavy; //900
    src: url(${AppleSDGothicNeoHeavy});
  }

  @font-face {
    font-family: AppleSDGothicNeoExtraBold; //800
    src: url(${AppleSDGothicNeoExtraBold});
  }

  @font-face {
    font-family: AppleSDGothicNeoBold; //700
    src: url(${AppleSDGothicNeoBold});
  }

  @font-face {
    font-family: AppleSDGothicNeoMedium; //500
    src: url(${AppleSDGothicNeoMedium});
  }

  @font-face {
    font-family: AppleSDGothicNeoRegular; //400
    src: url(${AppleSDGothicNeoRegular});
  }

  * {
    box-sizing: border-box;
    &::-webkit-scrollbar {
        /* width: 2px; */
        display: none;
    }
    /* &::-webkit-scrollbar-thumb {
        background-color: ${theme.colors.componentBG};
        border-radius: 4px;
    }
    &::-webkit-scrollbar-track {
        background-color: #fafafa;
        border-radius: 4px;
    } */
  }

  body {
    margin: 0;
    height: 0;
    width: 100%;
    height: 100%;
    font-size: 15px;
    font-family: AppleSDGothicNeoRegular, sans-serif;
    background-color: ${theme.colors.whiteColor};
    color: ${theme.colors.blackColor};
    overflow: scroll;
  }

  a {
    color: ${theme.colors.blackColor};
    text-decoration: none;
  }
  ul, ol{
    list-style: none;
  }

  textarea {
    resize: none;
  }

  input,
  textarea {
    font-size: 14px;
    border: none;
    outline: none;
    background-color: inherit;
    font-family: AppleSDGothicNeoRegular, sans-serif;

    &::placeholder {
      font-size: 14px;
    }

    &:disabled {
      color: ${theme.colors.blackColor};
      background: inherit;
    }
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }

  button {
    font-size: 15px;
    font-family: AppleSDGothicNeoRegular, sans-serif;
    padding: 0;
    outline: none;
    border: none;
    background-color: ${theme.colors.whiteColor};
    cursor: pointer;
  }

  select {
    font-size: 14px;
    font-family: AppleSDGothicNeoRegular, sans-serif;
    border: none;
    outline: none;
    background-color: transparent;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
  }

  .Toastify__toast {
    z-index: 30;
  }

  .MuiStack-root {
    padding-top: 0 !important;
  }

  .MuiPopper-root{
    top: 26px !important;
  }
`;
