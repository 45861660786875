import { useEffect, useState } from 'react';

const useCountdown = (targetDate: number) => {
    const [countDown, setCountDown] = useState(0);

    useEffect(() => {
        if (targetDate) {
            const interval = setInterval(() => {
                setCountDown(targetDate - new Date().getTime());
            }, 1000);

            return () => clearInterval(interval);
        } else {
            return;
        }
    }, [targetDate]);

    return getReturnValues(countDown);
};

const getReturnValues = (countDown: number) => {
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);
    const finished = Boolean(countDown && minutes + seconds === 0);

    return [minutes, seconds, finished];
};

export default useCountdown;
