import React, { ChangeEvent, KeyboardEvent, useState, memo } from 'react';
import styled from 'styled-components';
import theme from '@styles/theme';
import { IoEyeSharp, IoEyeOffSharp } from 'react-icons/io5';

type StyledPasswordProps = {
    width?: string;
    height?: number;
    margin?: string;
    border?: string;
    bgColor?: string;
    name?: string;
    value?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
    onBlur?: () => void;
    maxLength?: number;
    disabled?: boolean;
    placeholder?: string;
    LOGIN?: boolean;
};

const StyledPassword = ({
    width,
    height = 50,
    margin,
    border = `${theme.colors.componentBorder} 1px solid`,
    bgColor = theme.colors.whiteColor,
    name,
    value,
    onChange,
    onKeyDown,
    onBlur,
    maxLength = 200,
    disabled = false,
    placeholder,
    LOGIN,
}: StyledPasswordProps) => {
    const [visible, setVisible] = useState(false);

    return (
        <Wrapper $width={width} $height={height} $margin={margin}>
            <Input
                name={name}
                value={value}
                type={visible ? 'text' : 'password'}
                onChange={onChange}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                maxLength={maxLength}
                disabled={disabled}
                placeholder={LOGIN ? ' ' : placeholder}
                autoComplete="off"
                $border={border}
                $bgColor={bgColor}
            />
            <Label>{LOGIN ? placeholder : ''}</Label>
            <ViewButton onClick={() => setVisible(!visible)}>
                {visible ? (
                    <IoEyeOffSharp
                        fontSize={18}
                        color={theme.colors.disabled}
                    />
                ) : (
                    <IoEyeSharp fontSize={18} color={theme.colors.disabled} />
                )}
            </ViewButton>
        </Wrapper>
    );
};

export default memo(StyledPassword);

const Wrapper = styled.div<{
    $width?: string;
    $height?: number;
    $margin?: string;
}>`
    position: relative;
    margin: ${({ $margin }) => ($margin ? $margin : 0)};
    width: ${({ $width }) => ($width ? $width : '100%')};
    height: ${({ $height }) => $height}px;
`;

const Label = styled.label<{ LOGIN?: boolean }>`
    position: absolute;
    top: 16px;
    left: 0;
    left: 12px;
    color: ${theme.colors.disabled};
    pointer-events: none;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    /* display: ${({ LOGIN }) => (LOGIN ? 'block' : 'none')}; */
`;

const Input = styled.input<{
    $border?: string;
    $bgColor?: string;
}>`
    width: 100%;
    height: 100%;
    padding: 14px 40px 14px 12px;
    border: ${({ $border }) => $border};
    border-radius: 4px;
    background-color: ${({ $bgColor }) => $bgColor};
    font-family: AppleSDGothicNeoMedium, sans-serif;
    &::placeholder {
        color: ${theme.colors.disabled};
    }
    &:active:not(:disabled),
    &:focus:not(:disabled) {
        border: ${theme.colors.blackColor} 1px solid;
    }

    &:focus + label,
    &:not(:placeholder-shown) + label {
        top: -5px;
        left: 8px;
        padding: 0 4px;
        font-size: 12px;
        background-color: ${theme.colors.whiteColor};
    }

    &:focus + label {
        color: ${theme.colors.blackColor};
    }
`;

const ViewButton = styled.button`
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    cursor: pointer;
`;
