import { memo, useCallback } from 'react';
import styled from 'styled-components';

import theme from '@styles/theme';

import StyledModal from '@components/styled/StyledModal';
import StyledButton from '@components/styled/StyledButton';

type StatusChangePopupProps = {
    openPopup: boolean;
    setOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
    submitHandler: () => void;
};

const StatusChangePopup = ({
    openPopup,
    setOpenPopup,
    submitHandler,
}: StatusChangePopupProps) => {
    const closeModalHandler = useCallback(() => {
        setOpenPopup(false);
    }, [setOpenPopup]);
    return (
        <StyledModal
            title="알림"
            marginBottom={32}
            isOpen={openPopup}
            closeBtnHandler={closeModalHandler}
        >
            <Text>
                해당 상품에 대해 발송완료 상태로 전환되며, 취소할 수 없습니다.
                <br />
                그래도 진행하시겠어요?
            </Text>
            <ButtonWrapper>
                <StyledButton
                    title="취소"
                    width="140px"
                    fontColor={`${theme.colors.blackColor}`}
                    bgColor={`${theme.colors.whiteColor}`}
                    border={`${theme.colors.componentBorder} 1px solid`}
                    onClick={closeModalHandler}
                />
                <StyledButton
                    title="확인"
                    width="140px"
                    onClick={submitHandler}
                />
            </ButtonWrapper>
        </StyledModal>
    );
};

export default memo(StatusChangePopup);

const Text = styled.div`
    font-family: AppleSDGothicMedium, sans-serif;
    line-height: 1.3;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: end;
    gap: 8px;
    margin-top: 22px;
`;
