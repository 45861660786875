import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import dayjs from 'dayjs';
import { IoAddSharp } from 'react-icons/io5';
import { LiaDownloadSolid } from 'react-icons/lia';
import { toast } from 'react-toastify';

import * as P from './ParcelReceived.style';
import { ReceptionAdmin } from '@graphql/types';
import { userVar } from '@store/index';
import usePagination from '@hooks/usePagination';
// import { receivedParcelInfo } from '@lib/TEST_DUMMY_DATA';

import Pagination from '@components/layout/Pagination';
import ReceivedList from '@components/feature/ParcelReceived/ReceivedList';
import Loader from '@components/share/Loader';
import MuiTableCalendar from '@components/styled/MuiTableCalendar';

const ParcelReceived = () => {
    const navigate = useNavigate();
    const [type, setType] = useState('all');
    const [filter, setFilter] = useState('total');
    const [startDate, setStartDate] = useState<string | Date>(
        dayjs(new Date().setMonth(new Date().getMonth() - 1)).format(
            'YYYY-MM-DD',
        ),
    );
    const [endDate, setEndDate] = useState<string | Date>(
        dayjs(new Date()).format('YYYY-MM-DD'),
    );
    const [take, setTake] = useState(10);
    const [cursor, setCursor] = useState(0);
    // 페이지 그룹 변화 시 게시물 수. 0, take * 10, take * 20 씩으로 증감시켜 사용
    const [pageGroupLength, setPageGroupLength] = useState(0);
    // 데이터 수를 10으로 나누 것. ex) 0, 10, 20..
    const [pageIndex, setPageIndex] = useState(0);
    // 페이지네이션 셀렉트박스 show, hide
    const [paginationOpen, setPaginationOpen] = useState(false);

    const userInfo: ReceptionAdmin = useReactiveVar(userVar);
    const { receptionAdminPermission } = userInfo ?? {};
    const { rap_parcelPostReceive } = receptionAdminPermission ?? {};

    // 접근 권한 없는 사용자 접근 막기
    // useEffect(() => {
    //     if (rap_parcelPostReceive === false) {
    //         toast.warning('접근 권한이 없는 페이지 입니다.');
    //         navigate('/people');
    //     }
    // }, [rap_parcelPostReceive, navigate]);

    // 임시 데이터
    const receivedParcelInfo: any[] = [];
    const totalLength = 0;
    const totalCount = 0;
    const processedCount = 0;
    const unprocessedCount = 0;
    const loading = false;

    const downloadFileHandler = () => {
        // TODO: 추후 뮤테이션 추가
        console.log('download');
    };

    const resetPaginationHandler = useCallback(() => {
        setCursor(0); // 페이지네이션 초기화
        setPageIndex(0);
        setPageGroupLength(0);
    }, []);

    // 페이지네이션
    const {
        prevPageHandler,
        nextPageHandler,
        prevPageGroupHandler,
        nextPageGroupHandler,
    } = usePagination(
        take,
        totalLength as number,
        pageIndex,
        pageGroupLength,
        cursor,
        setPageIndex,
        setPageGroupLength,
        setCursor,
    );
    return (
        <P.Container>
            <P.Header>
                <P.TitleWrapper>
                    <P.TitleArea>
                        <P.Title>택배 및 등기 수령</P.Title>
                        <P.Select
                            name="serviceType"
                            id="serviceType"
                            value={type}
                            onChange={(
                                e: React.ChangeEvent<HTMLSelectElement>,
                            ) => setType(e.target.value)}
                        >
                            <P.Option value="all">전체</P.Option>
                            <P.Option value="registered">등기</P.Option>
                            <P.Option value="parcel">택배</P.Option>
                        </P.Select>
                    </P.TitleArea>
                    <P.BtnWrapper>
                        <P.LinkAddList to="/parcel-recipiency/registration">
                            <IoAddSharp size={24} />
                        </P.LinkAddList>
                        <P.BtnDownload onClick={downloadFileHandler}>
                            <LiaDownloadSolid size={20} />
                        </P.BtnDownload>
                    </P.BtnWrapper>
                </P.TitleWrapper>
                <P.FilterWrapper>
                    <P.StatusWrapper>
                        <P.Label htmlFor="total">
                            <P.Radio
                                type="radio"
                                id="total"
                                name="parcelReceived"
                                value="total"
                                checked={filter === 'total'}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                ) => setFilter(e.target.value)}
                            />
                            <P.FilterText>전체 {totalCount}</P.FilterText>
                        </P.Label>
                        <P.Label htmlFor="true">
                            <P.Radio
                                type="radio"
                                id="true"
                                name="parcelReceived"
                                value="true"
                                checked={filter === 'true'}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                ) => setFilter(e.target.value)}
                            />
                            <P.FilterText>
                                수령완료 {processedCount}
                            </P.FilterText>
                        </P.Label>
                        <P.Label htmlFor="false">
                            <P.Radio
                                type="radio"
                                id="false"
                                name="parcelReceived"
                                value="false"
                                checked={filter === 'false'}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                ) => setFilter(e.target.value)}
                            />
                            <P.FilterText>
                                미수령 {unprocessedCount}
                            </P.FilterText>
                        </P.Label>
                    </P.StatusWrapper>
                    <P.DatePickerWrapper>
                        <MuiTableCalendar
                            value={startDate}
                            onChange={setStartDate}
                        />
                        <P.IconWave>~</P.IconWave>
                        <MuiTableCalendar
                            value={endDate}
                            onChange={setEndDate}
                        />
                    </P.DatePickerWrapper>
                </P.FilterWrapper>
            </P.Header>
            <P.TableContainer>
                <ReceivedList />
                {loading && <Loader />}
                {(receivedParcelInfo as any[]).map((list: any, idx: number) => (
                    <ReceivedList key={`${idx}-${list?.pp_id}`} list={list} />
                ))}
                {!loading && !totalLength && (
                    <P.ListEmpty>조회된 리스트가 없습니다.</P.ListEmpty>
                )}
            </P.TableContainer>
            <Pagination
                paginationOpen={paginationOpen}
                setPaginationOpen={setPaginationOpen}
                pageGroupLength={pageGroupLength}
                totalLength={totalLength as number}
                take={take}
                cursor={cursor}
                setTake={setTake}
                setPageIndex={setPageIndex}
                setCursor={setCursor}
                prevPageHandler={prevPageHandler}
                nextPageHandler={nextPageHandler}
                prevPageGroupHandler={prevPageGroupHandler}
                nextPageGroupHandler={nextPageGroupHandler}
                resetPaginationHandler={resetPaginationHandler}
            />
        </P.Container>
    );
};

export default ParcelReceived;
