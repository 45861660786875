import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import theme from '@styles/theme';

type LoaderProps = {
    DISABLED?: boolean;
    BUTTON?: boolean;
    height?: string;
};

const Loader = ({ DISABLED, BUTTON, height }: LoaderProps) => {
    return (
        <Wrapper $disabled={DISABLED} $height={height}>
            <LoaderBox $disabled={DISABLED} $button={BUTTON} />
        </Wrapper>
    );
};

export default Loader;

export const Spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
export const Wrapper = styled.div<{ $disabled?: boolean; $height?: string }>`
    width: 100%;
    min-height: ${({ $height }) => ($height ? 'auto' : '200px')};
    height: ${({ $height }) => ($height ? $height : '100%')};
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ $disabled }) =>
        $disabled &&
        css`
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 100;
            margin: 0;
            background: rgba(0, 0, 0, 0.18);
        `};
`;
export const LoaderBox = styled.div<{ $button?: boolean; $disabled?: boolean }>`
    width: 50px;
    height: 50px;
    border: 5px solid ${theme.colors.componentBorder};
    border-top: 5px solid ${theme.colors.blackColor};
    border-radius: 50%;
    animation: ${Spin} 1.3s linear infinite;

    ${({ $button }) =>
        $button &&
        css`
            width: 28px;
            height: 28px;
            border-width: 4px;
        `};

    ${({ $disabled }) =>
        $disabled &&
        css`
            border-color: ${theme.colors.whiteColor};
            border-top-color: ${theme.colors.blackColor};
        `};
`;
