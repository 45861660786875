import { useState, useEffect } from 'react';

// 움직임 없을 시 로그아웃 되는 로직
const CheckEventLogout = () => {
    const isLoggedIn = sessionStorage.getItem('musinsa_reception_token')
        ? true
        : false;
    const [idleTime, setIdleTime] = useState(0);

    useEffect(() => {
        if (isLoggedIn) {
            const idleInterval = setInterval(() => {
                setIdleTime((prev) => (prev += 1));
            }, 1000);

            const mouseMove = () => setIdleTime(0);
            const keyPress = () => setIdleTime(0);

            document.addEventListener('mousemove', mouseMove);
            document.addEventListener('keypress', keyPress);

            if (idleTime >= 60 * 20) {
                alert('20분간 움직임이 없어 자동 로그아웃되었습니다.');
                sessionStorage.removeItem('musinsa_reception_token');
                window.location.href = '/';
            }

            return () => {
                document.removeEventListener('mousemove', mouseMove);
                document.removeEventListener('keypress', keyPress);
                clearInterval(idleInterval);
            };
        }
    }, [idleTime, isLoggedIn]);
    return null;
};

export default CheckEventLogout;
