import { useMutation } from '@apollo/client';
import { SetStateAction, useCallback, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import theme from '@styles/theme';
import { Mutation } from '@graphql/types';
import { UPDATE_RECEPTION_ADMIN_PW } from '@graphql/mutations';
import { useAuth } from '@context/authProvider';
import useEnter from '@hooks/useEnter';

import StyledModal from '@components/styled/StyledModal';
import StyledPassword from '@components/styled/StyledPassword';
import StyledButton from '@components/styled/StyledButton';

type ChangePasswordModalProps = {
    needToChangePw: boolean;
    setNeedToChangePw: React.Dispatch<SetStateAction<boolean>>;
};

const ChangePasswordModal = ({
    needToChangePw,
    setNeedToChangePw,
}: ChangePasswordModalProps) => {
    const [currentPw, setCurrentPw] = useState('');
    const [newPw, setNewPw] = useState('');
    const [confirmPw, setConfirmPw] = useState('');
    const [validation, setValidation] = useState({
        minLength: false,
        hasRequiredChars: false,
        hasNoRepeatedChars: false,
    });
    const { userLogout } = useAuth();
    const onEnter = useEnter();

    const [updateReceptionAdminPw] = useMutation<
        Pick<Mutation, 'updateReceptionAdminPw'>
    >(UPDATE_RECEPTION_ADMIN_PW);

    // 비밀번호 유효성 검사
    const validatePassword = useCallback((password: string) => {
        const hasRequiredChars =
            /(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])/.test(
                password,
            );
        const hasNoRepeatedChars = !/(.)\1{2,}/.test(password);

        return {
            minLength: password.length >= 8,
            hasRequiredChars,
            hasNoRepeatedChars,
        };
    }, []);

    // 새로운 비밀번호 value값 및 유효성 처리
    const newPwChangeHandler = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;
            setNewPw(value);
            setValidation(validatePassword(value));
        },
        [validatePassword],
    );

    const closeModalHandler = useCallback(() => {
        setNeedToChangePw(false);
    }, [setNeedToChangePw]);

    const submitHandler = useCallback(async () => {
        const { minLength, hasRequiredChars, hasNoRepeatedChars } = validation;
        if (!currentPw || !newPw || !confirmPw) {
            toast.warning('비밀번호를 입력해주세요.');
            return;
        }

        if (
            !minLength ||
            !hasRequiredChars ||
            !hasNoRepeatedChars ||
            newPw !== confirmPw
        ) {
            toast.warning('비밀번호를 정확하게 입력해주세요.');
            return;
        }
        await updateReceptionAdminPw({
            variables: {
                currentPw,
                newPw,
                confirmPw,
            },
            onCompleted: () => {
                alert('변경된 비밀번호로 다시 로그인해주세요.');
                setNeedToChangePw(false);
                userLogout();
            },
            onError: (err) => console.log(err.message),
        });
    }, [
        currentPw,
        newPw,
        confirmPw,
        updateReceptionAdminPw,
        setNeedToChangePw,
        validation,
        userLogout,
    ]);

    return (
        <StyledModal
            title="비밀번호 변경 (90일 경과)"
            marginBottom={32}
            isOpen={needToChangePw}
            closeBtnHandler={closeModalHandler}
        >
            <FormWrapper onSubmit={(e) => e.preventDefault()}>
                <InputTitle>현재 비밀번호</InputTitle>
                <StyledPassword
                    name="currentPw"
                    value={currentPw}
                    onChange={(e) => setCurrentPw(e.target.value)}
                    placeholder="현재 비밀번호"
                    margin="0 0 24px"
                />

                <InputTitle>새로운 비밀번호</InputTitle>
                <StyledPassword
                    name="newPw"
                    value={newPw}
                    onChange={newPwChangeHandler}
                    placeholder="새 비밀번호"
                    margin="0 0 12px"
                />
                <StyledPassword
                    name="confirmPw"
                    value={confirmPw}
                    onChange={(e) => setConfirmPw(e.target.value)}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                        onEnter(e, submitHandler)
                    }
                    placeholder="새 비밀번호 확인"
                    margin="0 0 12px"
                />
                <Condition>
                    <List $isChecked={validation.minLength}>
                        - 비밀번호 8자리 이상
                    </List>
                    <List $isChecked={validation.hasRequiredChars}>
                        - 대문자, 소문자, 숫자, 특수문자 포함
                    </List>
                    <List $isChecked={validation.hasNoRepeatedChars}>
                        - 반복되는 문자 사용 금지
                    </List>
                    <List $isChecked={newPw !== '' && newPw === confirmPw}>
                        - 비밀번호 확인
                    </List>
                </Condition>
                <ButtonWrapper>
                    <StyledButton
                        title="취소"
                        width="140px"
                        fontColor={`${theme.colors.blackColor}`}
                        bgColor={`${theme.colors.whiteColor}`}
                        border={`${theme.colors.componentBorder} 1px solid`}
                        onClick={closeModalHandler}
                    />
                    <StyledButton
                        title="저장하기"
                        width="140px"
                        onClick={submitHandler}
                    />
                </ButtonWrapper>
            </FormWrapper>
        </StyledModal>
    );
};

export default ChangePasswordModal;

const FormWrapper = styled.form``;

const InputTitle = styled.span`
    display: inline-block;
    margin-bottom: 8px;
    font-size: 14px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: end;
    gap: 8px;
    margin-top: 32px;
`;

const Condition = styled.ul`
    font-size: 14px;
    line-height: 1.5;
`;

const List = styled.li<{ $isChecked?: boolean }>`
    color: ${({ $isChecked }) =>
        $isChecked ? theme.colors.positive : theme.colors.description};
`;
