import { useMutation } from '@apollo/client';
import { useState, useCallback } from 'react';
import { RiArrowLeftLine } from 'react-icons/ri';
import { toast } from 'react-toastify';

import * as L from './Login.style';
import { RECEPTION_LOGIN } from '@graphql/mutations';
import { useAuth } from '@context/authProvider';
import useEnter from '@hooks/useEnter';

import StyledId from '@components/styled/StyledId';
import StyledPassword from '@components/styled/StyledPassword';
import StyledButton from '@components/styled/StyledButton';
import { Mutation } from '@graphql/types';

const Login = () => {
    const [inputs, setInputs] = useState({
        accountId: '',
        password: '',
    });
    const { userLogin } = useAuth();
    const onEnter = useEnter();

    const [receptionLogin] =
        useMutation<Pick<Mutation, 'receptionLogin'>>(RECEPTION_LOGIN);

    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const { name, value } = e.target;

            setInputs({
                ...inputs,
                [name]: value,
            });
        },
        [inputs],
    );

    const handleLogin = useCallback(async () => {
        const { accountId, password } = inputs;
        if (accountId === '' || password === '') {
            toast.warning('로그인 정보를 입력해주세요.');
            return;
        }

        await receptionLogin({
            variables: {
                accountId,
                password,
            },
            onCompleted: (data) => {
                userLogin(data.receptionLogin?.token as string);
            },
            onError: (err) => {
                console.log(err.message);
            },
        });
    }, [inputs, receptionLogin, userLogin]);

    const handleInquery = () => {
        alert(
            '담당자 정보 \nES팀 최지은 (jieun.choi@musinsa.com) \n위 담당자에게 문의해주세요.',
        );
    };

    return (
        <>
            <L.Header>
                <L.Wrapper>
                    <L.PrevBtn to={`${process.env.REACT_APP_RECEPTION_DOMAIN}`}>
                        <RiArrowLeftLine size={48} />
                    </L.PrevBtn>
                </L.Wrapper>
            </L.Header>
            <L.Container>
                <L.Title>로그인</L.Title>
                <L.Text>리셉션 관리자 전용 페이지입니다.</L.Text>
                <L.Form onSubmit={(e) => e.preventDefault()}>
                    <StyledId
                        name="accountId"
                        value={inputs.accountId}
                        onChange={onChange}
                        placeholder="아이디"
                        margin="0 0 12px"
                    />
                    <StyledPassword
                        name="password"
                        value={inputs.password}
                        onChange={onChange}
                        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                            onEnter(e, handleLogin)
                        }
                        placeholder="비밀번호"
                        margin="0 0 28px"
                        LOGIN
                    />
                    <StyledButton
                        title="로그인"
                        width="100%"
                        margin="0 0 15px"
                        onClick={handleLogin}
                    />
                </L.Form>
                <L.Inquery onClick={handleInquery}>비밀번호 문의</L.Inquery>
            </L.Container>
        </>
    );
};

export default Login;
