import { ParcelPost } from '@graphql/types';

export const parcelPostInfo: ParcelPost[] = [
    {
        pp_id: 1,
        pp_createdAt: '2024-09-04T07:04:07.437Z',
        pp_requestDate: '2024-09-04T07:04:07.437Z',
        pp_status: false,
        pp_requestor: '홍길동1',
        pp_requestorTeam: 'ES팀',
        pp_requestorCellphone: '01012345678',
        pp_receiver: '임꺽정',
        pp_receiverCellphone: '01098765432',
        pp_productName: '태블릿 PC',
        pp_address: '서울 성동구 옥수동 517-29번지 3층 / 이콤마이 오피스',
        pp_trackingNumber: '653868917124',
        pp_collectionScheduleDate: '2024-09-04T07:04:07.437Z',
    },
    {
        pp_id: 2,
        pp_createdAt: '2024-09-04T07:04:07.437Z',
        pp_requestDate: '2024-09-04T07:04:07.437Z',
        pp_status: false,
        pp_requestor: '홍길동2',
        pp_requestorTeam: 'ES팀',
        pp_requestorCellphone: '01012345678',
        pp_receiver: '임꺽정',
        pp_receiverCellphone: '01098765432',
        pp_productName: '태블릿 PC',
        pp_address: '서울 성동구 옥수동 517-29번지 3층 / 이콤마이 오피스',
        pp_trackingNumber: '',
        pp_collectionScheduleDate: '2024-09-04T07:04:07.437Z',
    },
    {
        pp_id: 3,
        pp_createdAt: '2024-09-04T07:04:07.437Z',
        pp_requestDate: '2024-09-04T07:04:07.437Z',
        pp_status: true,
        pp_requestor: '홍길동3',
        pp_requestorTeam: 'ES팀',
        pp_requestorCellphone: '01012345678',
        pp_receiver: '임꺽정',
        pp_receiverCellphone: '01098765432',
        pp_productName: '태블릿 PC',
        pp_address: '서울 성동구 옥수동 517-29번지 3층 / 이콤마이 오피스',
        pp_trackingNumber: '593999514374',
        pp_collectionScheduleDate: '2024-09-04T07:04:07.437Z',
    },
];

export const receivedParcelInfo: any[] = [
    {
        pp_id: 1,
        pp_type: 'parcel',
        pp_createdAt: '2024-09-04T07:04:07.437Z',
        pp_receivedDate: '2024-09-04T07:04:07.437Z',
        pp_keepDate: '2024-09-04T07:04:07.437Z',
        pp_status: false,
        pp_receiver: '임꺽정',
        pp_receiverTeam: 'ES팀',
        pp_receiverCellphone: '01098765432',
        pp_sender: '홍길동',
        pp_memo: '특이사항 입력',
        pp_manager: '',
    },
    {
        pp_id: 2,
        pp_type: 'registered',
        pp_createdAt: '2024-09-04T07:04:07.437Z',
        pp_receivedDate: '2024-09-04T07:04:07.437Z',
        pp_keepDate: '2024-09-04T07:04:07.437Z',
        pp_status: false,
        pp_receiver: '임꺽정',
        pp_receiverTeam: 'ES팀',
        pp_receiverCellphone: '01098765432',
        pp_sender: '홍길동',
        pp_memo: '특이사항 입력',
        pp_manager: '최배달',
    },
    {
        pp_id: 3,
        pp_type: 'registered',
        pp_createdAt: '2024-09-04T07:04:07.437Z',
        pp_receivedDate: '2024-09-04T07:04:07.437Z',
        pp_keepDate: '2024-09-04T07:04:07.437Z',
        pp_status: false,
        pp_receiver: '임꺽정',
        pp_receiverTeam: 'ES팀',
        pp_receiverCellphone: '01098765432',
        pp_sender: '홍길동',
        pp_memo: '특이사항 입력',
        pp_manager: '',
    },
    {
        pp_id: 4,
        pp_type: 'parcel',
        pp_createdAt: '2024-09-04T07:04:07.437Z',
        pp_receivedDate: '2024-09-04T07:04:07.437Z',
        pp_keepDate: '2024-09-04T07:04:07.437Z',
        pp_status: true,
        pp_receiver: '임꺽정',
        pp_receiverTeam: 'ES팀',
        pp_receiverCellphone: '01098765432',
        pp_sender: '홍길동',
        pp_memo: '특이사항 입력',
        pp_manager: '최배달',
    },
];

export const RecipientsList: string[] = [
    '홍길동',
    '홍길옹',
    '홍길홍',
    '임꺽정',
    '임정희',
    '아무개',
    '아무고양이',
    'David',
    'George',
];
