import { useState, useEffect, memo } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { IoCloseOutline } from 'react-icons/io5';
import theme from '@styles/theme';

type ModalProps = {
    children: React.ReactNode;
    title: string;
    marginBottom?: number;
    width?: string;
    closeBtnHandler?: () => void;
    isOpen?: boolean;
};
const StyledModal = ({
    children,
    title,
    marginBottom,
    width = '504px',
    closeBtnHandler,
    isOpen,
}: ModalProps) => {
    const [animation, setAnimation] = useState<boolean>(isOpen as boolean);

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    const unmountModalHandler = () => {
        setAnimation(false);
        setTimeout(() => {
            closeBtnHandler?.();
        }, 200);
    };

    return (
        <ModalArea>
            <ModalBackground
                // onClick={unmountModalHandler}
                $isOpen={animation}
            ></ModalBackground>
            <ModalContainer $width={width} $isOpen={animation}>
                <Header $marginBottom={marginBottom}>
                    <Title>{title}</Title>
                    <CloseBtn>
                        <IoCloseOutline
                            size={30}
                            color="#CACBD4"
                            onClick={unmountModalHandler}
                        />
                    </CloseBtn>
                </Header>
                {children}
            </ModalContainer>
        </ModalArea>
    );
};

export default memo(StyledModal);

const FadeIn = keyframes`
 from{
    opacity: 0;
  }
  to{
    opacity: .6;
  }
`;

const FadeOut = keyframes`
 from{
    opacity: .6;
  }
  to{
    opacity: 0;
  }
`;

const ShowContent = keyframes`
 from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`;

const CloseContent = keyframes`
 from{
    opacity: 1;
  }
  to{
    opacity: 0;
  }
`;

const ModalArea = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ModalBackground = styled.div<{ $isOpen: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${theme.colors.blackColor};
    opacity: 0.6;
    z-index: 20;
    animation: ${FadeOut} 0.2s ease-out forwards;
    ${({ $isOpen }) =>
        $isOpen &&
        css`
            animation-name: ${FadeIn};
        `};
`;

const ModalContainer = styled.div<{ $width?: string; $isOpen: boolean }>`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 32px 32px 40px;
    width: ${({ $width }) => $width};
    max-height: 100svh;
    background-color: ${theme.colors.whiteColor};
    z-index: 20;
    animation: ${CloseContent} 0.2s ease-out forwards;
    ${({ $isOpen }) =>
        $isOpen &&
        css`
            animation-name: ${ShowContent};
        `};
`;

const Header = styled.div<{ $marginBottom?: number }>`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${({ $marginBottom }) => $marginBottom}px;
`;

const Title = styled.h3`
    font-size: 16px;
    font-family: 'SF Pro Display', sans-serif;
    font-weight: 600;
`;

const CloseBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    svg {
        color: ${theme.colors.lightIconColor};
    }
`;
