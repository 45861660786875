import { useState, useMemo, useCallback, useEffect } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';

import * as M from './MuPeople.style';
import { SEE_MUPEOPLE_VISIT_CARD_LIST } from '@graphql/queries';
import { selectedSite, newMuPeople } from '@store/index';
import { Query, MuPeoplevisit } from '@graphql/types';
import usePagination from '@hooks/usePagination';
import { useAuth } from '@context/authProvider';

import Pagination from '@components/layout/Pagination';
import MuPeopleList from '@components/feature/MuPeople/MuPeopleList';
import Loader from '@components/share/Loader';

const MuPeople = () => {
    const selectedSiteCode = useReactiveVar(selectedSite);
    const muPeople = useReactiveVar(newMuPeople);
    const { userLogout } = useAuth();
    const [filter, setFilter] = useState('total');
    const [take, setTake] = useState(10);
    const [cursor, setCursor] = useState(0);
    // 페이지 그룹 변화 시 게시물 수. 0, take * 10, take * 20 씩으로 증감시켜 사용
    const [pageGroupLength, setPageGroupLength] = useState(0);
    // 데이터 수를 10으로 나누 것. ex) 0, 10, 20..
    const [pageIndex, setPageIndex] = useState(0);
    // 페이지네이션 셀렉트박스 show, hide
    const [paginationOpen, setPaginationOpen] = useState(false);

    const { data, loading, refetch } = useQuery<
        Pick<Query, 'seeMuPeopleVisitCardList'>
    >(SEE_MUPEOPLE_VISIT_CARD_LIST, {
        variables: {
            mpvcVisitCardStatus: filter,
            workSiteUnique: selectedSiteCode,
            take: take,
            cursor: cursor,
        },
        onCompleted: (data) => {
            if (!data.seeMuPeopleVisitCardList?.tokenExpired as boolean)
                userLogout();
        },
        onError: (err) => console.log(err),
    });

    // socket io 감지
    useEffect(() => {
        (async () => {
            if (muPeople) {
                await refetch();
            }
        })();
    }, [muPeople, refetch]);

    const {
        muPeoplevisitInfo = [],
        totalLength,
        totalCount,
        processedCount,
        unprocessedCount,
        returnCount,
        cancelCount,
    } = useMemo(() => {
        return data?.seeMuPeopleVisitCardList ?? {};
    }, [data?.seeMuPeopleVisitCardList]);

    const refetchHandler = useCallback(() => {
        refetch();
    }, [refetch]);

    const resetPaginationHandler = useCallback(() => {
        setCursor(0); // 페이지네이션 초기화
        setPageIndex(0);
        setPageGroupLength(0);
    }, []);

    // 페이지네이션
    const {
        prevPageHandler,
        nextPageHandler,
        prevPageGroupHandler,
        nextPageGroupHandler,
    } = usePagination(
        take,
        totalLength as number,
        pageIndex,
        pageGroupLength,
        cursor,
        setPageIndex,
        setPageGroupLength,
        setCursor,
    );
    return (
        <M.Container>
            <M.Header>
                <M.Title>무신사 피플 방문증 대여 현황</M.Title>
                <M.FilterWrapper>
                    <M.StatusWrapper>
                        <M.Label htmlFor="total">
                            <M.Radio
                                type="radio"
                                id="total"
                                name="status"
                                value="total"
                                checked={filter === 'total'}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                            <M.FilterText>전체 {totalCount}</M.FilterText>
                        </M.Label>
                        <M.Label htmlFor="processed">
                            <M.Radio
                                type="radio"
                                id="processed"
                                name="status"
                                value="processed"
                                checked={filter === 'processed'}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                            <M.FilterText>
                                방문증 발급 {processedCount}
                            </M.FilterText>
                        </M.Label>
                        <M.Label htmlFor="return">
                            <M.Radio
                                type="radio"
                                id="return"
                                name="status"
                                value="return"
                                checked={filter === 'return'}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                            <M.FilterText>
                                방문증 회수 {returnCount}
                            </M.FilterText>
                        </M.Label>
                        <M.Label htmlFor="unprocessed">
                            <M.Radio
                                type="radio"
                                id="unprocessed"
                                name="status"
                                value="unprocessed"
                                checked={filter === 'unprocessed'}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                            <M.FilterText>
                                미처리 {unprocessedCount}
                            </M.FilterText>
                        </M.Label>
                        <M.Label htmlFor="cancel">
                            <M.Radio
                                type="radio"
                                id="cancel"
                                name="status"
                                value="cancel"
                                checked={filter === 'cancel'}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                            <M.FilterText>취소 {cancelCount}</M.FilterText>
                        </M.Label>
                    </M.StatusWrapper>
                </M.FilterWrapper>
            </M.Header>
            <M.TableContainer>
                <MuPeopleList />
                {loading && <Loader />}
                {(muPeoplevisitInfo as MuPeoplevisit[]).map(
                    (list, idx: number) => (
                        <MuPeopleList
                            key={`${idx}-${list?.mpvc_id}`}
                            list={list as MuPeoplevisit}
                            refetch={refetchHandler}
                        />
                    ),
                )}
                {!loading && !totalLength && (
                    <M.ListEmpty>조회된 리스트가 없습니다.</M.ListEmpty>
                )}
            </M.TableContainer>
            <Pagination
                paginationOpen={paginationOpen}
                setPaginationOpen={setPaginationOpen}
                pageGroupLength={pageGroupLength}
                totalLength={totalLength as number}
                take={take}
                cursor={cursor}
                setTake={setTake}
                setPageIndex={setPageIndex}
                setCursor={setCursor}
                prevPageHandler={prevPageHandler}
                nextPageHandler={nextPageHandler}
                prevPageGroupHandler={prevPageGroupHandler}
                nextPageGroupHandler={nextPageGroupHandler}
                resetPaginationHandler={resetPaginationHandler}
            />
        </M.Container>
    );
};

export default MuPeople;
