import { useReactiveVar, useMutation } from '@apollo/client';
import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { IoChevronDown } from 'react-icons/io5';

import theme from '@styles/theme';
import LockIcon from '@assets/icons/lockIcon.svg';
import UnlockIcon from '@assets/icons/unlockIcon.svg';
import useCountdown from '@hooks/useCountdown';
import { useAuth } from '@context/authProvider';
import { userVar, siteVar, selectedSite } from '@store';
import {
    Mutation,
    ReceptionAdmin,
    ReceptionAdminSiteInfo,
} from '@graphql/types';
import { SEE_RECEPTION_ADMIN } from '@graphql/queries';
import { PRIV_AUTH_CANCEL_RECEPTION_ADMIN } from '@graphql/mutations';

import SecondAuthModal from '@components/share/SecondAuthModal';

const Header = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const site: string = useReactiveVar(selectedSite);
    const siteList: ReceptionAdminSiteInfo[] = useReactiveVar(siteVar);
    const userInfo: ReceptionAdmin = useReactiveVar(userVar);
    const { ra_name, ra_teamName, ra_site, ra_privAuth, ra_privAuthFinTime } =
        userInfo;

    const { userLogout } = useAuth();
    const [minutes, seconds, finished] = useCountdown(
        ra_privAuthFinTime as number,
    );

    const [privAuthCancelReceptionAdmin] = useMutation<
        Pick<Mutation, 'privAuthCancelReceptionAdmin'>
    >(PRIV_AUTH_CANCEL_RECEPTION_ADMIN);

    // 개인정보보기 2차 인증 만료 처리
    useEffect(() => {
        (async () => {
            if (ra_privAuth && finished) {
                await privAuthCancelReceptionAdmin({
                    refetchQueries: [SEE_RECEPTION_ADMIN],
                    onCompleted: () => window.location.reload(),
                    onError: (err) => console.log(err.message),
                });
            }
        })();
    }, [ra_privAuth, finished, privAuthCancelReceptionAdmin]);

    const handleLogout = () => {
        if (!window.confirm('정말 로그아웃 하시겠습니까?')) return;
        userLogout();
    };

    return (
        <>
            <Container>
                <MainHeader>
                    <BlackWrapper>
                        <Logo>MUSINSA RECEPTION</Logo>
                        <LogoutButton onClick={handleLogout}>
                            로그아웃
                        </LogoutButton>
                    </BlackWrapper>
                </MainHeader>
                <AdminInfo>
                    <WhiteWrapper>
                        <LeftInfo>
                            <User>{ra_name} 담당자</User>
                            <UserInfo>
                                {ra_teamName &&
                                    ra_site &&
                                    `주식회사 무신사 - ${ra_teamName} - 리셉션 - ${ra_site}`}
                                {(!ra_teamName || !ra_site) &&
                                    `주식회사 무신사 - 리셉션`}
                            </UserInfo>
                        </LeftInfo>
                        <RightInfo>
                            <>
                                {!ra_privAuth && (
                                    <PrivateInfo
                                        onClick={() => setIsModalOpen(true)}
                                    >
                                        <Img
                                            src={LockIcon}
                                            alt="자물쇠 아이콘"
                                        />
                                        개인정보 보기
                                    </PrivateInfo>
                                )}
                                {ra_privAuth && (
                                    <CountdownBox>
                                        <Img
                                            src={UnlockIcon}
                                            alt="자물쇠 아이콘"
                                        />
                                        <Countdown>
                                            {`${minutes}`.padStart(2, '0')}:
                                            {`${seconds}`.padStart(2, '0')} 남음
                                        </Countdown>
                                    </CountdownBox>
                                )}
                            </>
                            <Site>사이트:</Site>
                            <SelectWrapper>
                                <Select
                                    name="ra_site"
                                    id="ra_site"
                                    value={site}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        selectedSite(value);
                                        sessionStorage.setItem('site', value);
                                    }}
                                >
                                    {siteList.map((option, idx) => (
                                        <Option
                                            key={`${idx}-${option?.siteUnique}`}
                                            value={option.siteUnique as string}
                                        >
                                            {option.siteName}
                                        </Option>
                                    ))}
                                </Select>
                                <ArrowDown color={theme.colors.grayFont} />
                            </SelectWrapper>
                        </RightInfo>
                    </WhiteWrapper>
                </AdminInfo>
            </Container>
            {createPortal(
                isModalOpen && (
                    <SecondAuthModal
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                    />
                ),
                document.body,
            )}
        </>
    );
};

export default Header;

const Container = styled.header`
    width: 100%;
    z-index: 30;
`;

const MainHeader = styled.section`
    width: 100%;
    height: 100px;
    background-color: ${theme.colors.blackColor};
`;

const AdminInfo = styled.section`
    width: 100%;
    height: 50px;
    background-color: ${theme.colors.whiteColor};
    border-bottom: ${theme.colors.headerBorder} 1px solid;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0 30px;
    width: 1600px;
    /* width: 100%;
    max-width: 1600px; */
    height: inherit;
    ${theme.device.pc} {
        padding: 0;
    }
`;

const BlackWrapper = styled(Wrapper)`
    background-color: ${theme.colors.blackColor};
`;

const WhiteWrapper = styled(Wrapper)`
    border-bottom: ${theme.colors.headerBorder} 1px solid;
`;

const Logo = styled.h1`
    font-family: MusinsaMedium, sans-serif;
    font-size: 26px;
    color: ${theme.colors.whiteColor};
`;

const LogoutButton = styled.button`
    color: ${theme.colors.whiteColor};
    background-color: transparent;
`;

const LeftInfo = styled.div``;

const User = styled.span`
    margin-right: 50px;
    font-family: AppleSDGothicNeoBold, sans-serif;
`;

const UserInfo = styled.span`
    margin-right: 50px;
`;

const RightInfo = styled.div`
    display: flex;
    align-items: center;
    font-family: AppleSDGothicNeoMedium, sans-serif;
`;

const PrivateInfo = styled.button`
    display: flex;
    align-items: center;
    margin-right: 20px;
`;

const CountdownBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 20px;
    width: 105px;
`;

const Countdown = styled.span`
    display: inline-block;
    color: ${theme.colors.positive};
`;

const Img = styled.img`
    display: block;
    margin-right: 12px;
`;
const Site = styled.span`
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 2px;
    color: ${theme.colors.grayFont};
`;

const SelectWrapper = styled.div`
    position: relative;
    width: 110px;
    margin-bottom: 2px;
`;

const Select = styled.select`
    width: 110px;
`;

const Option = styled.option``;

const ArrowDown = styled(IoChevronDown)`
    position: absolute;
    top: 2px;
    right: 0;
    pointer-events: none;
`;
