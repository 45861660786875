import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ko';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { selectedDate } from '@store/index';

import theme from '@styles/theme';

export default function ResponsiveDatePickers({
    name,
    value,
    onChange,
}: {
    name?: string;
    value: string | Date;
    onChange: (value: string | Date) => void;
}) {
    const { pathname } = useLocation();

    const changeDateHandler = (date: Dayjs | null) => {
        onChange((date as Dayjs).toISOString());
        if (!pathname.includes('parcel'))
            return selectedDate((date as Dayjs).toISOString());
    };

    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={dayjs.locale('ko')}
        >
            <DemoContainer components={['DatePicker']}>
                <DatePickerWrapper>
                    <StyledPicker
                        name={name}
                        format="YYYY-MM-DD"
                        defaultValue={dayjs(value)}
                        onChange={(date) => changeDateHandler(date)}
                        autoFocus={false}
                        disableFuture
                    />
                </DatePickerWrapper>
            </DemoContainer>
        </LocalizationProvider>
    );
}

const DatePickerWrapper = styled.div`
    position: relative;
    width: 110px;
`;

const StyledPicker = styled(DatePicker)`
    width: fit-content;
    height: fit-content;

    .MuiInputBase-root {
        padding-right: 0 !important;
    }

    .MuiInputBase-input {
        padding: 0;
        height: auto !important;
        box-sizing: border-box !important;
        cursor: pointer !important;
        border: none !important;
        border-radius: 0;
        color: ${theme.colors.blackColor} !important;
        text-align: center;
        font-size: 18px;
        font-family: AppleSDGothicNeoBold, sans-serif;
    }
    .MuiOutlinedInput-notchedOutline {
        border: none;
    }
    .MuiInputAdornment-root {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        margin-left: 0;
        width: 100%;
        height: 23px;
    }
    .MuiButtonBase-root {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0;
        width: 100%;
        height: inherit;
        transition: none;
        border-radius: 0;
        &:hover {
            background-color: transparent !important;
        }
    }
    .MuiSvgIcon-root {
        display: none;
    }
    .MuiTouchRipple-root {
        display: none;
    }
`;
