import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import styled from 'styled-components';

import theme from '@styles/theme';
import { Mutation } from '@graphql/types';
import { AUTHENTICATE_RECEPTION_ADMIN } from '@graphql/mutations';
import { SEE_RECEPTION_ADMIN } from '@graphql/queries';
import useEnter from '@hooks/useEnter';

import StyledModal from '@components/styled/StyledModal';
import StyledInput from '@components/styled/StyledInput';
import StyledPassword from '@components/styled/StyledPassword';
import StyledButton from '@components/styled/StyledButton';

type SecondAuthModalPropsType = {
    isModalOpen: boolean;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const SecondAuthModal = ({
    isModalOpen,
    setIsModalOpen,
}: SecondAuthModalPropsType) => {
    const [accountId, setAccountId] = useState('');
    const [password, setPassword] = useState('');
    const onEnter = useEnter();

    const [authenticateReceptionAdmin] = useMutation<
        Pick<Mutation, 'authenticateReceptionAdmin'>
    >(AUTHENTICATE_RECEPTION_ADMIN);

    const submitHandler = useCallback(async () => {
        await authenticateReceptionAdmin({
            variables: {
                accountId,
                password,
            },
            refetchQueries: [SEE_RECEPTION_ADMIN],
            onCompleted: () => {
                setIsModalOpen(false);
                window.location.reload();
            },
            onError: (err) => console.log(err.message),
        });
    }, [authenticateReceptionAdmin, accountId, password, setIsModalOpen]);

    const closeModalHandler = useCallback(() => {
        setIsModalOpen(false);
    }, [setIsModalOpen]);

    return (
        <StyledModal
            title="개인정보 보기 2차 인증"
            marginBottom={40}
            isOpen={isModalOpen}
            closeBtnHandler={closeModalHandler}
        >
            <FormWrapper onSubmit={(e) => e.preventDefault()}>
                <StyledInput
                    value={accountId}
                    onChange={(e) => setAccountId(e.target.value)}
                    placeholder="아이디"
                    maxLength={30}
                    margin="0 0 12px"
                />
                <StyledPassword
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                        onEnter(e, submitHandler)
                    }
                    placeholder="비밀번호"
                />
                <ButtonWrapper>
                    <StyledButton
                        title="취소"
                        width="140px"
                        fontColor={`${theme.colors.blackColor}`}
                        bgColor={`${theme.colors.whiteColor}`}
                        border={`${theme.colors.componentBorder} 1px solid`}
                        onClick={closeModalHandler}
                    />
                    <StyledButton
                        title="인증하기"
                        width="140px"
                        onClick={submitHandler}
                    />
                </ButtonWrapper>
            </FormWrapper>
        </StyledModal>
    );
};

export default SecondAuthModal;

const FormWrapper = styled.form``;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: end;
    gap: 8px;
    margin-top: 32px;
`;
