import { useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { IoArrowBackSharp } from 'react-icons/io5';
import { toast } from 'react-toastify';

import theme from '@styles/theme';
import { ParcelPost } from '@graphql/types';
import { autoHyphen } from '@utils/common';

import Header from '@components/layout/Header';
import StyledInput from '@components/styled/StyledInput';
import StyledButton from '@components/styled/StyledButton';
import MuiDatePicker from '@components/styled/MuiDatePicker';
import StatusChangePopup from './StatusChangePopup';

type TrackingNumberProps = {
    info: ParcelPost;
    setTrackingNumModal: React.Dispatch<React.SetStateAction<boolean>>;
};
const RegisterTrackingNumber = ({
    info,
    setTrackingNumModal,
}: TrackingNumberProps) => {
    const [openPopup, setOpenPopup] = useState(false);
    const [trackingNum, setTrackingNum] = useState('');
    const [expectedDate, setExpectedDate] = useState<string | Date>(
        dayjs(new Date()).toISOString(),
    );

    const {
        pp_id,
        pp_createdAt,
        pp_requestDate,
        pp_requestor,
        pp_requestorTeam,
        pp_requestorCellphone,
        pp_receiver,
        pp_receiverCellphone,
        pp_productName,
        pp_address,
    } = info ?? {};

    const openPopupHandler = () => {
        if (trackingNum && expectedDate) {
            setOpenPopup(true);
        } else {
            toast.warning('필수 정보를 입력해주세요.');
        }
    };

    const submitHandler = useCallback(() => {
        // TODO: 추후 뮤테이션 연결
        setTrackingNumModal(false);
        setOpenPopup(false);
    }, [setTrackingNumModal]);

    return (
        <>
            <Container>
                <Header />
                <ContentContainer>
                    <GoBackArea>
                        <GoBack onClick={() => setTrackingNumModal(false)}>
                            <IoArrowBackSharp
                                size={36}
                                color={theme.colors.blackColor}
                            />
                        </GoBack>
                    </GoBackArea>
                    <MainContainer>
                        <InfoWrapper>
                            <Title>송장번호 수동 입력</Title>
                            <InfoArea>
                                <InfoType>요청정보</InfoType>
                                <InfoListArea>
                                    <InfoList>
                                        <InfoTitle>요청일시</InfoTitle>
                                        <InfoText>
                                            {dayjs(pp_requestDate).format(
                                                'YYYY-MM-DD HH:mm',
                                            )}
                                        </InfoText>
                                    </InfoList>
                                    <InfoList>
                                        <InfoTitle>요청자</InfoTitle>
                                        <InfoText>
                                            {pp_requestor} / {pp_requestorTeam}
                                        </InfoText>
                                    </InfoList>
                                    <InfoList>
                                        <InfoTitle>연락처</InfoTitle>
                                        <InfoText>
                                            {autoHyphen(
                                                pp_requestorCellphone as string,
                                            )}
                                        </InfoText>
                                    </InfoList>
                                    <InfoList>
                                        <InfoTitle>받는분</InfoTitle>
                                        <InfoText>{pp_receiver}</InfoText>
                                    </InfoList>
                                    <InfoList>
                                        <InfoTitle>받는분 연락처</InfoTitle>
                                        <InfoText>
                                            {autoHyphen(
                                                pp_receiverCellphone as string,
                                            )}
                                        </InfoText>
                                    </InfoList>
                                    <InfoList>
                                        <InfoTitle>상품명</InfoTitle>
                                        <InfoText>{pp_productName}</InfoText>
                                    </InfoList>
                                    <InfoList>
                                        <InfoTitle>주소</InfoTitle>
                                        <InfoText>{pp_address}</InfoText>
                                    </InfoList>
                                </InfoListArea>
                            </InfoArea>
                            <InputArea>
                                <InfoType>처리정보</InfoType>
                                <InfoListArea>
                                    <InfoInput>
                                        <InfoTitleBold>
                                            운송장번호
                                        </InfoTitleBold>
                                        <StyledInput
                                            value={trackingNum}
                                            onChange={(e) =>
                                                setTrackingNum(e.target.value)
                                            }
                                            width="calc(100% - 180px)"
                                            placeholder="운송장번호를 입력해주세요."
                                        />
                                    </InfoInput>
                                    <InfoInput>
                                        <InfoTitleBold>
                                            집화예정일
                                        </InfoTitleBold>
                                        <MuiDatePicker
                                            value={expectedDate}
                                            onChange={(date) =>
                                                setExpectedDate(date)
                                            }
                                        />
                                    </InfoInput>
                                </InfoListArea>
                            </InputArea>
                            <BtnArea>
                                <StyledButton
                                    title="발송완료"
                                    width="140px"
                                    onClick={openPopupHandler}
                                />
                            </BtnArea>
                        </InfoWrapper>
                    </MainContainer>
                </ContentContainer>
            </Container>
            {createPortal(
                openPopup && (
                    <StatusChangePopup
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                        submitHandler={submitHandler}
                    />
                ),
                document.body,
            )}
        </>
    );
};

export default RegisterTrackingNumber;

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${theme.colors.whiteColor};
    z-index: 20;
`;

const ContentContainer = styled.section`
    display: flex;
    justify-content: space-between;
    margin: 0 auto 100px;
    padding: 0 30px;
    max-width: 1600px;
    height: calc(100% - 150px);
    overflow: auto;
    ${theme.device.pc} {
        padding: 0;
    }
`;

const GoBackArea = styled.nav`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 40px;
    width: 200px;
    height: auto;
`;

const GoBack = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    width: 36px;
    height: 36px;
`;

const MainContainer = styled.main`
    padding-top: 100px;
    width: calc(100% - 240px);
`;

const InfoWrapper = styled.section`
    width: 100%;
    max-width: 620px;
`;

const Title = styled.h2`
    font-size: 32px;
    font-family: AppleSDGothicNeoBold, sans-serif;
`;

const InfoArea = styled.div`
    margin-top: 32px;
`;

const InputArea = styled.div`
    margin-top: 40px;
`;

const InfoType = styled.span`
    display: block;
    margin-bottom: 24px;
    font-size: 18px;
    font-family: AppleSDGothicNeoBold, sans-serif;
`;

const InfoListArea = styled.ul``;

const InfoList = styled.li`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

const InfoInput = styled(InfoList)`
    margin-bottom: 12px;
`;

const InfoTitle = styled.span`
    display: block;
    width: 180px;
    color: ${theme.colors.grayFont};
`;

const InfoTitleBold = styled(InfoTitle)`
    color: ${theme.colors.blackColor};
    font-family: AppleSDGothicNeoBold, sans-serif;
`;

const InfoText = styled.span`
    font-family: AppleSDGothicNeoMedium, sans-serif;
`;

const BtnArea = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    padding-bottom: 100px;
`;
