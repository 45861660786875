import { makeVar } from '@apollo/client';
import { ReceptionAdminSiteInfo } from '@graphql/types';
import dayjs from 'dayjs';

const userInfo = {} as any;
const siteList = [] as ReceptionAdminSiteInfo[];
const today = dayjs(new Date()).toISOString();

export const userVar = makeVar(userInfo);
export const siteVar = makeVar(siteList);

export const selectedDate = makeVar(today);
export const selectedSite = makeVar('');
//socket io 트리거
export const newVisitor = makeVar(false);
export const newMuPeople = makeVar(false);
export const newParking = makeVar(false);
