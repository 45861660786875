import styled from 'styled-components';
import theme from '@styles/theme';
import { Link } from 'react-router-dom';
import arrowDown from '@assets/icons/arrowDownB.svg';

export const Container = styled.main`
    width: calc(100% - 240px);
`;

export const Header = styled.div`
    margin-top: 100px;
    font-family: AppleSDGothicNeoBold, sans-serif;
`;

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
`;

export const TitleArea = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;

export const Title = styled.h2`
    font-size: 32px;
`;

export const Select = styled.select`
    width: 50px;
    font-size: 18px;
    font-family: AppleSDGothicNeoBold, sans-serif;
    background-image: url(${arrowDown});
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 14px;
`;

export const Option = styled.option``;

export const BtnWrapper = styled.div`
    display: flex;
`;

export const LinkAddList = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    cursor: pointer;
`;

export const BtnDownload = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
`;

export const FilterWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 26px;
`;

export const StatusWrapper = styled.div``;

export const Label = styled.label`
    input:checked + span {
        color: ${theme.colors.blackColor};
    }
`;

export const Radio = styled.input`
    display: none;
`;

export const FilterText = styled.span`
    display: inline-block;
    margin-right: 20px;
    font-size: 18px;
    color: ${theme.colors.disabled};
    cursor: pointer;
`;

export const DatePickerWrapper = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const IconWave = styled.span`
    font-size: 18px;
    margin: 0 8px;
`;

export const TableContainer = styled.ul`
    width: 100%;
    border-top: ${theme.colors.blackColor} 4px solid;
`;

export const ListEmpty = styled.li`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    border-bottom: ${theme.colors.componentBorder} 1px solid;
`;
