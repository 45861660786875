import { useState, useMemo, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useReactiveVar } from '@apollo/client';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import * as V from './Visitor.style';
import { SEE_VISITORS_LIST_V2 } from '@graphql/queries';
import { Query, Visitors, ReceptionAdmin } from '@graphql/types';
import { selectedSite, newVisitor, userVar } from '@store/index';
import usePagination from '@hooks/usePagination';
import { useAuth } from '@context/authProvider';

import Pagination from '@components/layout/Pagination';
import VisitorList from '@components/feature/Visitor/VisitorList';
import Loader from '@components/share/Loader';
import MuiTableCalendar from '@components/styled/MuiTableCalendar';

const Visitor = () => {
    const navigate = useNavigate();
    const [filter, setFilter] = useState('total');
    const [searchDate, setSearchDate] = useState<string | Date>(
        dayjs(new Date()).toISOString(),
    );
    const [take, setTake] = useState(10);
    const [cursor, setCursor] = useState(0);
    // 페이지 그룹 변화 시 게시물 수. 0, take * 10, take * 20 씩으로 증감시켜 사용
    const [pageGroupLength, setPageGroupLength] = useState(0);
    // 데이터 수를 10으로 나누 것. ex) 0, 10, 20..
    const [pageIndex, setPageIndex] = useState(0);
    // 페이지네이션 셀렉트박스 show, hide
    const [paginationOpen, setPaginationOpen] = useState(false);

    const { userLogout } = useAuth();
    const selectedSiteCode = useReactiveVar(selectedSite);
    const visitor = useReactiveVar(newVisitor);
    const userInfo: ReceptionAdmin = useReactiveVar(userVar);
    const { receptionAdminPermission } = userInfo ?? {};
    const { rap_visitor } = receptionAdminPermission ?? {};

    // 접근 권한 없는 사용자 접근 막기
    useEffect(() => {
        if (rap_visitor === false) {
            toast.warning('접근 권한이 없는 페이지 입니다.');
            navigate('/people');
        }
    }, [rap_visitor, navigate]);

    const {
        data: visitorData,
        loading: visitorLoading,
        refetch: visitorRefetch,
    } = useQuery<Pick<Query, 'seeVisitorsList_v2'>>(SEE_VISITORS_LIST_V2, {
        variables: {
            searchDate,
            rvVisitorCardStatus: filter,
            siteUnique: selectedSiteCode,
            take,
            cursor,
        },
        onCompleted: (data) => {
            if (!data.seeVisitorsList_v2?.tokenExpired as boolean) userLogout();
        },
        onError: (err) => console.log(err),
    });

    // socket io 감지
    useEffect(() => {
        (async () => {
            if (visitor) {
                await visitorRefetch();
            }
        })();
    }, [visitor, visitorRefetch]);

    const {
        visitorsInfo = [],
        totalLength,
        totalCount,
        processedCount,
        unprocessedCount,
        returnCount,
        cancelCount,
    } = useMemo(() => {
        return visitorData?.seeVisitorsList_v2 ?? {};
    }, [visitorData?.seeVisitorsList_v2]);

    const refetchHandler = useCallback(() => {
        visitorRefetch();
    }, [visitorRefetch]);

    const resetPaginationHandler = useCallback(() => {
        setCursor(0); // 페이지네이션 초기화
        setPageIndex(0);
        setPageGroupLength(0);
    }, []);

    // 페이지네이션
    const {
        prevPageHandler,
        nextPageHandler,
        prevPageGroupHandler,
        nextPageGroupHandler,
    } = usePagination(
        take,
        totalLength as number,
        pageIndex,
        pageGroupLength,
        cursor,
        setPageIndex,
        setPageGroupLength,
        setCursor,
    );

    return (
        <V.Container>
            <V.Header>
                <V.Title>방문자 등록 현황</V.Title>
                <V.FilterWrapper>
                    <V.StatusWrapper>
                        <V.Label htmlFor="total">
                            <V.Radio
                                type="radio"
                                id="total"
                                name="visitorPass"
                                value="total"
                                checked={filter === 'total'}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                            <V.FilterText>전체 {totalCount}</V.FilterText>
                        </V.Label>
                        <V.Label htmlFor="processed">
                            <V.Radio
                                type="radio"
                                id="processed"
                                name="visitorPass"
                                value="processed"
                                checked={filter === 'processed'}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                            <V.FilterText>
                                방문증 발급 {processedCount}
                            </V.FilterText>
                        </V.Label>
                        <V.Label htmlFor="return">
                            <V.Radio
                                type="radio"
                                id="return"
                                name="visitorPass"
                                value="return"
                                checked={filter === 'return'}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                            <V.FilterText>
                                방문증 회수 {returnCount}
                            </V.FilterText>
                        </V.Label>
                        <V.Label htmlFor="unprocessed">
                            <V.Radio
                                type="radio"
                                id="unprocessed"
                                name="visitorPass"
                                value="unprocessed"
                                checked={filter === 'unprocessed'}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                            <V.FilterText>
                                미처리 {unprocessedCount}
                            </V.FilterText>
                        </V.Label>
                        <V.Label htmlFor="cancel">
                            <V.Radio
                                type="radio"
                                id="cancel"
                                name="visitorPass"
                                value="cancel"
                                checked={filter === 'cancel'}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                            <V.FilterText>취소 {cancelCount}</V.FilterText>
                        </V.Label>
                    </V.StatusWrapper>
                    <MuiTableCalendar
                        value={searchDate}
                        onChange={setSearchDate}
                    />
                </V.FilterWrapper>
            </V.Header>
            <V.TableContainer>
                <VisitorList />
                {visitorLoading && <Loader />}
                {(visitorsInfo as Visitors[]).map((list, idx: number) => (
                    <VisitorList
                        key={`${idx}-${list?.rv_id}`}
                        list={list as Visitors}
                        refetch={refetchHandler}
                    />
                ))}
                {!visitorLoading && !totalLength && (
                    <V.ListEmpty>조회된 리스트가 없습니다.</V.ListEmpty>
                )}
            </V.TableContainer>
            <Pagination
                paginationOpen={paginationOpen}
                setPaginationOpen={setPaginationOpen}
                pageGroupLength={pageGroupLength}
                totalLength={totalLength as number}
                take={take}
                cursor={cursor}
                setTake={setTake}
                setPageIndex={setPageIndex}
                setCursor={setCursor}
                prevPageHandler={prevPageHandler}
                nextPageHandler={nextPageHandler}
                prevPageGroupHandler={prevPageGroupHandler}
                nextPageGroupHandler={nextPageGroupHandler}
                resetPaginationHandler={resetPaginationHandler}
            />
        </V.Container>
    );
};

export default Visitor;
