import { useCallback, useState } from 'react';
import dayjs from 'dayjs';

import * as R from './RegisterReceivedParcel.style';
import { SelectChangeEvent } from '@mui/material';
import { SERVICE_TYPE_OPTIONS } from '@constants/index';
import { RecipientsList } from '@lib/TEST_DUMMY_DATA';

import StyledInput from '@components/styled/StyledInput';
import StyledButton from '@components/styled/StyledButton';
import StyledComboBox from '@components/styled/StyledComboBox';
import MuiDatePicker from '@components/styled/MuiDatePicker';
import MuiSelect from '@components/styled/MuiSelect';

const RegisterReceivedParcel = () => {
    const [keepingDate, setKeepingDate] = useState<string | Date>(
        dayjs(new Date()).toISOString(),
    );
    const [recipient, setRecipient] = useState('');
    const [possibleList, setPossibleList] = useState(RecipientsList); // TODO: 추후 Dummy data 대체 필요
    const [comboBox, setComboBox] = useState(false);
    const [info, setInfo] = useState({
        type: 'parcel',
        sender: '',
        memo: '',
    });

    const infoUpdateHandler = useCallback(
        (
            e:
                | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
                | SelectChangeEvent<string>,
        ) => {
            const { name, value } = e.target;

            setInfo((prev) => {
                return {
                    ...prev,
                    [name]: value,
                };
            });
        },
        [],
    );

    const submitHandler = useCallback(() => {
        // TODO: 추후 뮤테이션 연결
        const { type, sender, memo } = info;
        alert(
            `${dayjs(keepingDate).format(
                'YYYY-MM-DD',
            )}, ${type}, ${recipient}, ${sender}, ${memo}`,
        );
    }, [info, keepingDate, recipient]);

    return (
        <R.Container>
            <R.InfoWrapper>
                <R.Title>택배 및 등기 수령 정보 입력하기</R.Title>
                <R.InfoArea>
                    <R.InfoType>수령정보</R.InfoType>
                    <R.InfoListArea>
                        <R.InfoList>
                            <R.InfoTitle>보관일</R.InfoTitle>
                            <MuiDatePicker
                                name="keepingDate"
                                value={keepingDate}
                                onChange={(date) => {
                                    setKeepingDate(date);
                                }}
                            />
                        </R.InfoList>
                        <R.InfoList>
                            <R.InfoTitle>구분</R.InfoTitle>
                            <MuiSelect
                                name="type"
                                value={info.type}
                                onChange={(e) => infoUpdateHandler(e)}
                                options={SERVICE_TYPE_OPTIONS}
                                width="calc(100% - 180px)"
                            />
                        </R.InfoList>
                        <R.InfoList>
                            <R.InfoTitle>수령예정인</R.InfoTitle>
                            <StyledComboBox
                                name="recipient"
                                value={recipient}
                                setValue={setRecipient}
                                list={possibleList}
                                setList={setPossibleList}
                                comboBox={comboBox}
                                setComboBox={setComboBox}
                                fullList={RecipientsList} // TODO: 추후 Dummy data 대체 필요
                                placeholder="수령예정인을 검색해주세요."
                                width="calc(100% - 180px)"
                            />
                        </R.InfoList>
                        <R.InfoList>
                            <R.InfoTitle>발송인</R.InfoTitle>
                            <StyledInput
                                name="sender"
                                value={info.sender}
                                onChange={(e) => infoUpdateHandler(e)}
                                width="calc(100% - 180px)"
                                placeholder="성함을 입력해주세요."
                            />
                        </R.InfoList>
                        <R.InfoList>
                            <R.InfoTitle>특이사항</R.InfoTitle>
                            <StyledInput
                                name="memo"
                                value={info.memo}
                                onChange={(e) => infoUpdateHandler(e)}
                                width="calc(100% - 180px)"
                                placeholder="특이사항을 입력해주세요."
                            />
                        </R.InfoList>
                    </R.InfoListArea>
                </R.InfoArea>
                <R.BtnArea>
                    <StyledButton
                        title="입력완료"
                        width="140px"
                        onClick={submitHandler}
                    />
                </R.BtnArea>
            </R.InfoWrapper>
        </R.Container>
    );
};

export default RegisterReceivedParcel;
