import styled from 'styled-components';
import { darken } from 'polished';

import theme from '@styles/theme';

export const Container = styled.main`
    padding-top: 100px;
    width: calc(100% - 240px);
`;

export const InfoWrapper = styled.section`
    width: 100%;
    max-width: 620px;
`;

export const Title = styled.h2`
    font-size: 32px;
    font-family: AppleSDGothicNeoBold, sans-serif;
`;

export const InfoArea = styled.div`
    margin-top: 32px;
`;

export const InfoType = styled.span`
    display: block;
    font-size: 18px;
    font-family: AppleSDGothicNeoBold, sans-serif;
`;

export const InfoListArea = styled.ul`
    margin-top: 24px;
`;

export const InfoList = styled.li`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
`;

export const InfoTitle = styled.span`
    display: block;
    width: 180px;
    color: ${theme.colors.blackColor};
    font-family: AppleSDGothicNeoBold, sans-serif;
`;

export const InputComboBox = styled.div`
    position: relative;
    width: calc(100% - 180px);
`;

export const ComboBox = styled.ul`
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    max-height: 200px;
    background-color: ${theme.colors.whiteColor};
    border-radius: 8px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.3);
    overflow-y: scroll;
    z-index: 10;
`;

export const ComboList = styled.li`
    padding: 15px 12px;
    width: 100%;
    background-color: ${theme.colors.whiteColor};
    cursor: pointer;
    &:hover {
        background-color: ${darken(0.05, theme.colors.whiteColor)};
    }
`;
export const BtnArea = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    padding-bottom: 100px;
`;
