import { useMutation } from '@apollo/client';
import { useState, useEffect, memo, useCallback } from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';
import { toast } from 'react-toastify';
import { SelectChangeEvent } from '@mui/material';

import theme from '@styles/theme';
import { Mutation, MuPeoplevisit } from '@graphql/types';
import { dateFormat, autoHyphen } from '@utils/common';
import {
    INITIAL_STATUS_OPTIONS,
    PROCESSED_STATUS_OPTIONS,
} from '@constants/index';
import { UPDATE_MUPEOPLE_VISIT_CARD_STATUS } from '@graphql/mutations';
import { useAuth } from '@context/authProvider';

import MuiSelect from '@components/styled/MuiSelect';
import RegisterPassModal from '@components/share/RegisterPassModal';
import RequestCancelModal from '@components/share/RequestCancelModal';

type MuPeopleListProps = {
    list?: MuPeoplevisit;
    refetch?: () => void;
};

const MuPeopleList = ({ list, refetch }: MuPeopleListProps) => {
    const { userLogout } = useAuth();
    const [status, setStatus] = useState('');
    const [processedModal, setProcessedModal] = useState(false);
    const [cancelModal, setCancelModal] = useState(false);
    const [cardSerial, setCardSerial] = useState('');
    const [reason, setReason] = useState('');
    const {
        mpvc_id,
        mpvc_visitCardStatus,
        mpvc_createdAt,
        mpvc_visitCardSerial,
        mpvc_teamName,
        mpvc_name,
        mpvc_cellphone,
        mpvc_returnDate,
        mpvc_reason,
        mpvc_managerTeamName,
        mpvc_managerName,
    } = list ?? {};

    useEffect(() => {
        setStatus(mpvc_visitCardStatus as string);
    }, [mpvc_visitCardStatus]);

    const [updateMuPeopleVisitCardStatus] = useMutation<
        Pick<Mutation, 'updateMuPeopleVisitCardStatus'>
    >(UPDATE_MUPEOPLE_VISIT_CARD_STATUS);

    const submitHandler = useCallback(
        async (statusType: string) => {
            await updateMuPeopleVisitCardStatus({
                variables: {
                    mpvcId: mpvc_id,
                    mpvcVisitCardStatus: statusType,
                    mpvcVisitCardSerial: cardSerial ? cardSerial : '',
                    mpvcCancelReason: reason ? reason : '',
                },
                onCompleted: (data) => {
                    if (
                        !data.updateMuPeopleVisitCardStatus
                            ?.tokenExpired as boolean
                    )
                        userLogout();
                    refetch!();
                    toast.success('방문자 등록 상태를 변경했습니다.');
                },
                onError: (err) => {
                    console.log(err);
                    setCardSerial('');
                    setReason('');
                    switch (statusType) {
                        case 'return':
                            setStatus('processed');
                            break;
                        default:
                            setStatus('unprocessed');
                    }
                },
            });
        },
        [
            cardSerial,
            mpvc_id,
            reason,
            refetch,
            updateMuPeopleVisitCardStatus,
            userLogout,
        ],
    );

    const changeSelectHandler = async (
        e:
            | React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
            | SelectChangeEvent,
    ) => {
        const { value } = e.target;
        switch (value) {
            case 'processed':
                setStatus(value);
                setProcessedModal(true);
                break;
            case 'cancel':
                setStatus(value);
                setCancelModal(true);
                break;
            case 'return':
                if (!window.confirm('방문증을 회수하시겠습니까?')) return;
                await submitHandler(value);
                break;
        }
    };

    if (!list)
        return (
            <TableTitle>
                <CellStatus>상태</CellStatus>
                <CellRequestDate>요청일시</CellRequestDate>
                <CellVisitPass>방문증</CellVisitPass>
                <CellTeam>소속</CellTeam>
                <CellName>이름</CellName>
                <CellPhoneNum>연락처</CellPhoneNum>
                <CellExpectedDate>반납예정일</CellExpectedDate>
                <CellReason>대여사유</CellReason>
                <CellManager>담당자명</CellManager>
            </TableTitle>
        );

    return (
        <>
            <Container $disabled={status === 'return' || status === 'cancel'}>
                <CellStatus>
                    <MuiSelect
                        name="status"
                        value={status}
                        onChange={(e) => changeSelectHandler(e)}
                        options={
                            status === 'processed' || status === 'return'
                                ? PROCESSED_STATUS_OPTIONS
                                : INITIAL_STATUS_OPTIONS
                        }
                        border={false}
                        disabled={status === 'return' || status === 'cancel'}
                    />
                </CellStatus>
                <CellRequestDate>
                    {
                        dateFormat(
                            mpvc_createdAt as string,
                            'yyyy-MM-dd hh:mm',
                        ) as string
                    }
                </CellRequestDate>
                <CellVisitPass>
                    {mpvc_visitCardSerial ? mpvc_visitCardSerial : '-'}
                </CellVisitPass>
                <CellTeam>{mpvc_teamName}</CellTeam>
                <CellName>{mpvc_name}</CellName>
                <CellPhoneNum>
                    {autoHyphen(mpvc_cellphone as string)}
                </CellPhoneNum>
                <CellExpectedDate>
                    {
                        dateFormat(
                            mpvc_returnDate as string,
                            'yyyy-MM-dd',
                        ) as string
                    }
                </CellExpectedDate>
                <CellReason>{mpvc_reason}</CellReason>
                <CellManager>{`${
                    mpvc_managerTeamName ? mpvc_managerTeamName : ''
                } ${mpvc_managerName ? mpvc_managerName : '-'}`}</CellManager>
            </Container>
            {createPortal(
                processedModal && (
                    <RegisterPassModal
                        id={mpvc_id as number}
                        refetch={refetch as () => void}
                        status={status}
                        cardSerial={cardSerial}
                        setStatus={setStatus}
                        setProcessedModal={setProcessedModal}
                        setCardSerial={setCardSerial}
                        submitHandler={submitHandler}
                    />
                ),
                document.body,
            )}
            {createPortal(
                cancelModal && (
                    <RequestCancelModal
                        id={mpvc_id as number}
                        refetch={refetch as () => void}
                        status={status}
                        reason={reason}
                        setStatus={setStatus}
                        setCancelModal={setCancelModal}
                        setReason={setReason}
                        submitHandler={submitHandler}
                    />
                ),
                document.body,
            )}
        </>
    );
};

export default memo(MuPeopleList);

const Container = styled.li<{ $disabled: boolean }>`
    display: flex;
    height: 50px;
    border-bottom: ${theme.colors.componentBorder} 1px solid;

    ${({ $disabled }) =>
        $disabled &&
        css`
            color: ${theme.colors.disabled};
        `};

    ${({ $disabled }) =>
        !$disabled &&
        css`
            &:hover {
                background-color: ${theme.colors.componentBG};
            }
        `};
`;

const TableTitle = styled.li`
    display: flex;
    height: 50px;
    border-bottom: ${theme.colors.blackColor} 1px solid;
`;

const TableCell = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
`;

const CellStatus = styled(TableCell)`
    flex: 10%;
`;

const CellRequestDate = styled(TableCell)`
    flex: 11%;
`;

const CellVisitPass = styled(TableCell)`
    flex: 8%;
`;

const CellTeam = styled(TableCell)`
    flex: 12%;
`;

const CellName = styled(TableCell)`
    flex: 7%;
`;

const CellPhoneNum = styled(TableCell)`
    flex: 9%;
`;

const CellExpectedDate = styled(TableCell)`
    flex: 11%;
`;

const CellReason = styled(TableCell)`
    flex: 22%;
`;

const CellManager = styled(TableCell)`
    flex: 10%;
`;
