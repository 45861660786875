import { useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';

import theme from '@styles/theme';

import StyledModal from '@components/styled/StyledModal';
import StyledInput from '@components/styled/StyledInput';
import StyledButton from '@components/styled/StyledButton';

type RequestCancelModalProps = {
    id: number;
    refetch: () => void;
    status: string;
    reason: string;
    setStatus: React.Dispatch<React.SetStateAction<string>>;
    setCancelModal: React.Dispatch<React.SetStateAction<boolean>>;
    setReason: React.Dispatch<React.SetStateAction<string>>;
    submitHandler: (satus: string) => void;
};

const RequestCancelModal = ({
    id,
    refetch,
    status,
    reason,
    setStatus,
    setCancelModal,
    setReason,
    submitHandler,
}: RequestCancelModalProps) => {
    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        ref.current!.focus();
    }, []);

    const closeModalHandler = useCallback(() => {
        setStatus('unprocessed');
        setCancelModal(false);
        setReason('');
    }, [setStatus, setCancelModal, setReason]);

    const onSubmit = useCallback(() => {
        if (!reason) {
            alert('취소 사유를 입력해주세요.');
            return;
        }
        submitHandler(status);
        setCancelModal(false);
    }, [reason, setCancelModal, status, submitHandler]);

    return (
        <StyledModal
            title="방문증 요청 취소하기"
            marginBottom={40}
            isOpen={status === 'cancel'}
            closeBtnHandler={closeModalHandler}
        >
            <StyledInput
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                placeholder="취소 사유를 입력해주세요"
                ref={ref}
            />
            <ButtonWrapper>
                <StyledButton
                    title="취소"
                    width="140px"
                    fontColor={`${theme.colors.blackColor}`}
                    bgColor={`${theme.colors.whiteColor}`}
                    border={`${theme.colors.componentBorder} 1px solid`}
                    onClick={closeModalHandler}
                />
                <StyledButton
                    title="요청취소"
                    width="140px"
                    onClick={() => {
                        onSubmit();
                    }}
                />
            </ButtonWrapper>
        </StyledModal>
    );
};

export default RequestCancelModal;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: end;
    gap: 8px;
    margin-top: 32px;
`;
