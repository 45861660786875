import { memo, useCallback, useState } from 'react';
import styled from 'styled-components';

import theme from '@styles/theme';
import { RecipientsList } from '@lib/TEST_DUMMY_DATA';

import StyledModal from '@components/styled/StyledModal';
import StyledButton from '@components/styled/StyledButton';
import StyledComboBox from '@components/styled/StyledComboBox';

type AddRecipientPopupProps = {
    recipientPopup: boolean;
    setRecipientPopup: React.Dispatch<React.SetStateAction<boolean>>;
    submitHandler: () => void;
};

const AddRecipientPopup = ({
    recipientPopup,
    setRecipientPopup,
    submitHandler,
}: AddRecipientPopupProps) => {
    const [recipient, setRecipient] = useState('');
    const [possibleList, setPossibleList] = useState(RecipientsList);
    const [comboBox, setComboBox] = useState(false);

    const closeModalHandler = useCallback(() => {
        setRecipientPopup(false);
    }, [setRecipientPopup]);

    return (
        <StyledModal
            title="수령인 입력"
            marginBottom={32}
            isOpen={recipientPopup}
            closeBtnHandler={closeModalHandler}
        >
            <StyledComboBox
                name="recipient"
                value={recipient}
                setValue={setRecipient}
                list={possibleList}
                setList={setPossibleList}
                comboBox={comboBox}
                setComboBox={setComboBox}
                fullList={RecipientsList} // TODO: 추후 Dummy data 대체 필요
                placeholder="수령인을 검색해주세요."
            />
            <ButtonWrapper>
                <StyledButton
                    title="취소"
                    width="140px"
                    fontColor={`${theme.colors.blackColor}`}
                    bgColor={`${theme.colors.whiteColor}`}
                    border={`${theme.colors.componentBorder} 1px solid`}
                    onClick={closeModalHandler}
                />
                <StyledButton
                    title="저장하기"
                    width="140px"
                    onClick={submitHandler}
                />
            </ButtonWrapper>
        </StyledModal>
    );
};

export default memo(AddRecipientPopup);

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: end;
    gap: 8px;
    margin-top: 22px;
`;
