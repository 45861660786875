export const dateFormat = (dateString: string, type: string) => {
    let year;
    let month;
    let day;
    let week;
    let hour;
    let min;
    let date = new Date(parseInt(dateString, 10));

    switch (type) {
        case 'yyyy.MM.dd':
            year = date.getFullYear().toString();
            month = date.getMonth() + 1;
            day = date.getDate();

            month = month >= 10 ? month : '0' + month;
            day = day >= 10 ? day : '0' + day;

            return year + '. ' + month + '. ' + day + ` (${week})`;
        case 'yyyy-MM-dd':
            year = date.getFullYear().toString();
            month = date.getMonth() + 1;
            day = date.getDate();

            month = month >= 10 ? month : '0' + month;
            day = day >= 10 ? day : '0' + day;

            return year + '-' + month + '-' + day;
        case 'yyyy-MM-dd hh:mm':
            year = date.getFullYear().toString();
            month = date.getMonth() + 1;
            day = date.getDate();
            hour = date.getHours();
            min = date.getMinutes();

            month = month >= 10 ? month : '0' + month;
            day = day >= 10 ? day : '0' + day;

            hour = hour >= 10 ? hour : '0' + hour;
            min = min >= 10 ? min : '0' + min;

            return `${year}-${month}-${day} ${hour}:${min}`;
        default:
            return date;
    }
};

// 휴대전화 Hyphen 넣기
export const autoHyphen = (phoneNumber: string) => {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
        3,
        7,
    )}-${phoneNumber.slice(7, 11)}`;
};
