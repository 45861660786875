import { memo } from 'react';
import styled from 'styled-components';
import { darken } from 'polished';

import theme from '@styles/theme';

import StyledInput from './StyledInput';

type StyledComboBoxProps = {
    name?: string;
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    list: any[];
    setList: React.Dispatch<React.SetStateAction<any[]>>;
    comboBox: boolean;
    setComboBox: React.Dispatch<React.SetStateAction<boolean>>;
    fullList: any[];
    placeholder?: string;
    width?: string;
};

const StyledComboBox = ({
    name,
    value,
    setValue,
    list,
    setList,
    comboBox,
    setComboBox,
    fullList,
    placeholder,
    width,
}: StyledComboBoxProps) => {
    return (
        <InputComboBox $width={width}>
            <StyledInput
                name={name}
                value={value}
                onChange={(e) => {
                    setValue(e.target.value);
                    setList(
                        fullList.filter((name) =>
                            name.includes(e.target.value),
                        ),
                    );
                }}
                onFocus={(e) => {
                    setComboBox(true);
                    setList(
                        fullList.filter((name) =>
                            name.includes(e.target.value),
                        ),
                    );
                }}
                onBlur={() => setTimeout(() => setComboBox(false), 200)}
                placeholder={placeholder}
                SEARCH
            />
            {comboBox && (
                <ComboBox>
                    {list.map((name, idx) => (
                        <ComboList
                            key={`${idx}-${name}`}
                            data-name={name}
                            onClick={(e) => {
                                setValue((e.target as HTMLLIElement).innerText);
                            }}
                        >
                            {name}
                        </ComboList>
                    ))}
                </ComboBox>
            )}
        </InputComboBox>
    );
};

export default memo(StyledComboBox);

const InputComboBox = styled.div<{ $width?: string }>`
    position: relative;
    width: ${({ $width }) => ($width ? $width : 'auto')};
`;

const ComboBox = styled.ul`
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    max-height: 200px;
    background-color: ${theme.colors.whiteColor};
    border-radius: 8px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.3);
    overflow-y: scroll;
    z-index: 10;
`;

const ComboList = styled.li`
    padding: 15px 12px;
    width: 100%;
    background-color: ${theme.colors.whiteColor};
    cursor: pointer;
    &:hover {
        background-color: ${darken(0.05, theme.colors.whiteColor)};
    }
`;
