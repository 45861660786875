import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import styled from 'styled-components';

import theme from '@styles/theme';

type KeyValueStringType = {
    [key: string]: string;
};

type MuiSelectTypes = {
    name: string;
    value: string;
    onChange: (
        e:
            | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
            | SelectChangeEvent,
    ) => void;
    options: KeyValueStringType[];
    placeholder?: string;
    width?: string;
    disabled?: boolean;
    border?: boolean;
};

const MuiSelect = ({
    name,
    value,
    onChange,
    options,
    placeholder,
    width,
    disabled,
    border = true,
}: MuiSelectTypes) => {
    return (
        <SelectWrapper
            $width={width}
            $placeholder={value}
            disabled={disabled}
            $border={border}
        >
            <Select
                name={name}
                id={name}
                value={value}
                onChange={onChange}
                displayEmpty
            >
                {placeholder && (
                    <MenuItem value="" disabled>
                        {placeholder}
                    </MenuItem>
                )}
                {options.map((option: KeyValueStringType, idx: number) => (
                    <MenuItem
                        key={`${idx}-${option.value}`}
                        value={option.value}
                    >
                        {option.name}
                    </MenuItem>
                ))}
            </Select>
        </SelectWrapper>
    );
};

export default MuiSelect;

const SelectWrapper = styled(FormControl)<{
    $width?: string;
    $placeholder: string;
    $border?: boolean;
}>`
    width: ${({ $width }) => ($width ? `${$width}` : 'auto')};
    font-family: AppleSDGothicNeoMedium, sans-serif;

    .MuiSelect-select {
        padding: 14px 12px;
        font-size: 15px;
        color: ${({ $placeholder }) =>
            $placeholder.length !== 0
                ? theme.colors.blackColor
                : theme.colors.disabled} !important;
        border: ${({ $border }) =>
            $border
                ? `${theme.colors.componentBorder} 1px solid !important`
                : 'none'};
        &:hover,
        &:focus {
            border: ${({ $border }) =>
                    $border ? `${theme.colors.blackColor}` : 'none'}
                1px solid !important;
        }
        &.Mui-disabled {
            color: ${theme.colors.disabled} !important;
            cursor: auto !important;
            -webkit-text-fill-color: unset !important;
        }
    }
    .MuiOutlinedInput-notchedOutline {
        border: none;
    }
    .MuiSvgIcon-root {
        color: ${theme.colors.darkIconColor}!important;
    }
`;
