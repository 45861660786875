import { memo, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Route, Routes } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import styled from 'styled-components';

import theme from '@styles/theme';
import { userVar } from '@store';
import { ReceptionAdmin } from '@graphql/types';

import Header from '@components/layout/Header';
import SideNavigation from '@components/layout/SideNavigation';
import Login from '@pages/Login';
import Visitor from '@pages/Visitor';
import MuPeople from '@pages/MuPeople';
import Parking from '@pages/Parking';
import ParcelShipping from '@pages/PackageShipping';
import ParcelReceived from '@pages/ParcelReceived';
import RegisterReceivedParcel from '@pages/RegisterReceivedParcel';
import NotFound from '@pages/NotFound';

import ChangePasswordModal from '@components/share/ChangePasswordModal';

const LoggedOutRoutes = memo(() => (
    <Routes>
        <Route path="/*" element={<Login />} />
    </Routes>
));

const LoggedInRoutes = memo(() => {
    // 로그인 직후 체크 상태
    const [rightAfterLogin, setRightAfterLogin] = useState(false);
    const [needToChangePw, setNeedToChangePw] = useState(false);

    const userInfo: ReceptionAdmin = useReactiveVar(userVar);
    const { ra_lastLoginDate, ra_passwordInit, ra_lastPasswordUpdate } =
        userInfo;

    // 로그인 직후엔만 true
    useEffect(() => {
        const justLoggedIn =
            Date.now() - new Date(ra_lastLoginDate as string).getTime() <
            3 * 1000; // 로그인 직후 체크
        setRightAfterLogin(justLoggedIn);
        setTimeout(() => {
            setRightAfterLogin(false);
        }, 800);
    }, [ra_lastLoginDate]);

    // 비밀번호 변경 기간 체크 (3개월)
    useEffect(() => {
        const lastUpdate = new Date(ra_lastPasswordUpdate as string);
        const diffMilliseconds = Date.now() - lastUpdate.getTime();
        const diffDate = Math.floor(
            Math.abs(diffMilliseconds / (1000 * 60 * 60 * 24)),
        );

        // 비밀번호 초기화를 했거나 변경 3개월이 넘었을 경우, 로그인 직후에만 모달 오픈
        if (rightAfterLogin && (ra_passwordInit || diffDate > 90)) {
            setNeedToChangePw(true);
        }
    }, [ra_lastPasswordUpdate, ra_passwordInit, rightAfterLogin]);

    return (
        <>
            <Header />
            <Container>
                <SideNavigation />
                <Routes>
                    <Route path="/visitor" element={<Visitor />} />
                    <Route path="/people" element={<MuPeople />} />
                    <Route path="/parking" element={<Parking />} />
                    <Route
                        path="/parcel-shipment"
                        element={<ParcelShipping />}
                    />
                    <Route
                        path="/parcel-recipiency"
                        element={<ParcelReceived />}
                    />
                    <Route
                        path="/parcel-recipiency/registration"
                        element={<RegisterReceivedParcel />}
                    />
                    <Route path="/*" element={<NotFound />} />
                </Routes>
            </Container>
            {createPortal(
                needToChangePw && (
                    <ChangePasswordModal
                        needToChangePw={needToChangePw}
                        setNeedToChangePw={setNeedToChangePw}
                    />
                ),
                document.body,
            )}
        </>
    );
});

const Router = ({ isLoggedIn = true }: { isLoggedIn: boolean }) => {
    if (!isLoggedIn) return <LoggedOutRoutes />;

    return <LoggedInRoutes />;
};

export default Router;

const Container = styled.section`
    display: flex;
    justify-content: space-between;
    margin: 0 auto 100px;
    padding: 0 30px;
    width: 1600px;
    /* max-width: 1600px; */
    ${theme.device.pc} {
        padding: 0;
    }
`;
