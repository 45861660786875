import { gql } from '@apollo/client';

export const SEE_RECEPTION_ADMIN = gql`
    query SeeReceptionAdmin {
        seeReceptionAdmin {
            receptionAdmin {
                ra_id
                ra_createdAt
                ra_updatedAt
                userType
                ra_accountId
                ra_site
                ra_siteUnique
                ra_teamName
                ra_employerCode
                ra_name
                ra_groupJoinDate
                ra_birthday
                ra_address
                ra_cellphone
                ra_passwordInit
                ra_lastLoginDate
                ra_lastPasswordUpdate
                ra_privAuth
                ra_privAuthFinTime
                receptionAdminPermission {
                    rap_id
                    rap_visitor
                    rap_parking
                    rap_parcelPost
                    rap_parcelPostReceive
                }
            }
        }
    }
`;

export const SEE_RECEPTION_ADMIN_SITE_LIST = gql`
    query SeeReceptionAdminStieList {
        seeReceptionAdminStieList {
            tokenExpired
            receptionAdminSiteList {
                siteName
                siteUnique
            }
        }
    }
`;

export const SEE_RECEPTION_COUNT = gql`
    query SeeReceptionCount($searchDate: String!, $workSiteUnique: String!) {
        seeReceptionCount(
            searchDate: $searchDate
            workSiteUnique: $workSiteUnique
        ) {
            tokenExpired
            visitorsCount
            parkingCount
            muPeopleCount
        }
    }
`;

export const SEE_VISITORS_LIST_V2 = gql`
    query SeeVisitorsList_v2(
        $searchDate: String!
        $rvVisitorCardStatus: visitorCardStatus!
        $siteUnique: String!
        $take: Int!
        $cursor: Int!
    ) {
        seeVisitorsList_v2(
            searchDate: $searchDate
            rv_visitorCardStatus: $rvVisitorCardStatus
            siteUnique: $siteUnique
            take: $take
            cursor: $cursor
        ) {
            tokenExpired
            totalLength
            totalCount
            processedCount
            unprocessedCount
            returnCount
            cancelCount
            visitorsInfo {
                rv_id
                rv_createdAt
                rv_site
                rv_siteUnique
                rv_type
                rv_team
                rv_companyName
                rv_name
                rv_cellPhone
                rv_visitPath
                rv_carRegistStatus
                rv_carLicenseNumber
                rv_hour
                rv_min
                rv_managerName
                rv_privacyPolicyAgreeDate
                rv_visitorCardStatus
                rv_visitorCardSerial
            }
        }
    }
`;

export const SEE_MUPEOPLE_VISIT_CARD_LIST = gql`
    query SeeMuPeopleVisitCardList(
        $mpvcVisitCardStatus: visitorCardStatus!
        $workSiteUnique: String!
        $take: Int
        $cursor: Int
    ) {
        seeMuPeopleVisitCardList(
            mpvc_visitCardStatus: $mpvcVisitCardStatus
            workSiteUnique: $workSiteUnique
            take: $take
            cursor: $cursor
        ) {
            tokenExpired
            totalLength
            totalCount
            processedCount
            unprocessedCount
            returnCount
            cancelCount
            muPeoplevisitInfo {
                mpvc_id
                mpvc_visitCardStatus
                mpvc_createdAt
                mpvc_visitCardSerial
                mpvc_teamName
                mpvc_name
                mpvc_cellphone
                mpvc_returnDate
                mpvc_reason
                mpvc_managerTeamName
                mpvc_managerName
            }
        }
    }
`;

export const SEE_VISITORS_PARKING_LIST_V2 = gql`
    query SeeVisitorsParkingList_v2(
        $searchDate: String!
        $rvCarRegistStatus: visitorParkingStatus!
        $workSiteUnique: String!
        $take: Int!
        $cursor: Int!
    ) {
        seeVisitorsParkingList_v2(
            searchDate: $searchDate
            rv_carRegistStatus: $rvCarRegistStatus
            workSiteUnique: $workSiteUnique
            take: $take
            cursor: $cursor
        ) {
            tokenExpired
            totalLength
            totalCount
            processedCount
            unprocessedCount
            cancelCount
            visitorsParkingInfo {
                rv_id
                rv_createdAt
                rv_site
                rv_siteUnique
                rv_type
                rv_team
                rv_companyName
                rv_name
                rv_cellPhone
                rv_visitPath
                rv_carRegistStatus
                rv_carLicenseNumber
                rv_hour
                rv_min
                rv_managerName
                rv_privacyPolicyAgreeDate
                rv_visitorCardStatus
                rv_visitorCardSerial
            }
        }
    }
`;

// FIXME: 백엔드 체크 필요
export const SEE_PARCEL_POST_LIST = gql`
    query SeeParcelPostList(
        $searchDate: String!
        $ppStatus: String
        $take: Int
        $cursor: Int
    ) {
        seeParcelPostList(
            searchDate: $searchDate
            pp_status: $ppStatus
            take: $take
            cursor: $cursor
        ) {
            tokenExpired
            totalLength
            totalCount
            processedCount
            unprocessedCount
            parcelPostInfo {
                pp_id
                pp_createdAt
                pp_requestDate
                pp_status
                pp_requestor
                pp_requestorTeam
                pp_requestorCellphone
                pp_receiver
                pp_receiverCellphone
                pp_productName
                pp_address
                pp_trackingNumber
                pp_collectionScheduleDate
            }
        }
    }
`;
